import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Wordle на български" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Преведена с много ❤️ и ръчно подбрани думи от {' '}  
        <a
          href="https://boomraid.bg"
          className="underline font-bold"
        >
          BoomRaid&trade; 
        </a> - градските приключенски игри в София, Пловдив, Варна и Бургас.
      </p>
    </BaseModal>
  )
}
