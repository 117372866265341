export const VALIDGUESSES = [
  `абата`,
  `абате`,
  `абати`,
  `абдал`,
  `абеба`,
  `абзац`,
  `абите`,
  `аборт`,
  `абрит`,
  `аванс`,
  `авари`,
  `авгур`,
  `авеню`,
  `авера`,
  `авери`,
  `авизо`,
  `авоар`,
  `аврам`,
  `аврен`,
  `автор`,
  `агава`,
  `агаве`,
  `агата`,
  `агент`,
  `агите`,
  `агнец`,
  `агова`,
  `агора`,
  `агори`,
  `адаша`,
  `адаши`,
  `адепт`,
  `админ`,
  `адове`,
  `адови`,
  `адрес`,
  `адска`,
  `адски`,
  `адско`,
  `аероб`,
  `ажура`,
  `азера`,
  `азери`,
  `азмак`,
  `азота`,
  `айдъл`,
  `айков`,
  `айляк`,
  `аймак`,
  `айран`,
  `айрес`,
  `айтос`,
  `акажу`,
  `акала`,
  `акали`,
  `акало`,
  `акана`,
  `акане`,
  `акани`,
  `акант`,
  `акара`,
  `акари`,
  `акате`,
  `акорд`,
  `акрил`,
  `актив`,
  `актов`,
  `актът`,
  `акула`,
  `акули`,
  `акциз`,
  `акции`,
  `акция`,
  `акъла`,
  `акъли`,
  `акъра`,
  `албум`,
  `алгол`,
  `алеко`,
  `алекс`,
  `алена`,
  `алени`,
  `алено`,
  `алест`,
  `алжир`,
  `алиби`,
  `алино`,
  `алкал`,
  `алкид`,
  `аллах`,
  `алтай`,
  `алтов`,
  `алтън`,
  `алтът`,
  `алцек`,
  `алчев`,
  `алчен`,
  `алчна`,
  `алчни`,
  `алчно`,
  `алюра`,
  `алюри`,
  `амбра`,
  `амвон`,
  `амеба`,
  `амеби`,
  `амиго`,
  `ампер`,
  `ампир`,
  `амура`,
  `амури`,
  `анали`,
  `ангел`,
  `ангро`,
  `анекс`,
  `анета`,
  `анжел`,
  `анима`,
  `аниме`,
  `анион`,
  `анита`,
  `анкер`,
  `анков`,
  `анода`,
  `аноди`,
  `анонс`,
  `антов`,
  `антон`,
  `антре`,
  `ануса`,
  `анфас`,
  `анцуг`,
  `анчев`,
  `аншоа`,
  `анюта`,
  `аорта`,
  `аорти`,
  `апапа`,
  `апахи`,
  `апача`,
  `апаче`,
  `апачи`,
  `апаши`,
  `апела`,
  `апели`,
  `аплет`,
  `аплик`,
  `апнея`,
  `апорт`,
  `април`,
  `араби`,
  `арапи`,
  `аргал`,
  `аргат`,
  `аргон`,
  `ареал`,
  `арена`,
  `арени`,
  `арест`,
  `арзан`,
  `ариец`,
  `аркан`,
  `армии`,
  `армия`,
  `арсен`,
  `арсов`,
  `артур`,
  `артък`,
  `артът`,
  `аруба`,
  `арфов`,
  `архар`,
  `архат`,
  `архив`,
  `арчар`,
  `аршин`,
  `асата`,
  `аскер`,
  `аскет`,
  `аслан`,
  `асове`,
  `асото`,
  `аспра`,
  `астма`,
  `астра`,
  `астри`,
  `асцит`,
  `атака`,
  `атаки`,
  `аташе`,
  `атила`,
  `атина`,
  `атлаз`,
  `атлас`,
  `атлет`,
  `атове`,
  `атола`,
  `атоли`,
  `атома`,
  `атоми`,
  `аудио`,
  `ауста`,
  `афган`,
  `афект`,
  `афера`,
  `афери`,
  `афикс`,
  `афион`,
  `афиша`,
  `афиши`,
  `ахала`,
  `ахали`,
  `ахане`,
  `ахата`,
  `ахати`,
  `ахвам`,
  `ахеец`,
  `ахкам`,
  `ахмак`,
  `ахнал`,
  `ахчии`,
  `ахчия`,
  `ацтек`,
  `ашика`,
  `ашици`,
  `ашуре`,
  `аязма`,
  `аязмо`,
  `аянин`,
  `бабек`,
  `бабин`,
  `бабка`,
  `бабки`,
  `бабук`,
  `бабяк`,
  `бавел`,
  `бавен`,
  `бавил`,
  `бавим`,
  `бавиш`,
  `бавна`,
  `бавни`,
  `бавно`,
  `бавят`,
  `багаж`,
  `багер`,
  `багет`,
  `багра`,
  `багри`,
  `багря`,
  `бадем`,
  `баден`,
  `баджа`,
  `баджи`,
  `баева`,
  `баене`,
  `базар`,
  `базел`,
  `базис`,
  `базов`,
  `баира`,
  `баири`,
  `байка`,
  `байко`,
  `байно`,
  `байта`,
  `байчо`,
  `бакии`,
  `бакия`,
  `бакла`,
  `бакли`,
  `бакър`,
  `балев`,
  `балей`,
  `бален`,
  `балет`,
  `балик`,
  `бална`,
  `бални`,
  `бално`,
  `балон`,
  `балта`,
  `балша`,
  `балък`,
  `балът`,
  `банан`,
  `банат`,
  `банги`,
  `банда`,
  `банди`,
  `банев`,
  `банер`,
  `банка`,
  `банки`,
  `банкя`,
  `банов`,
  `бараж`,
  `барай`,
  `барак`,
  `барал`,
  `барам`,
  `баран`,
  `барат`,
  `барах`,
  `бараш`,
  `барби`,
  `барва`,
  `барда`,
  `барел`,
  `барем`,
  `баржа`,
  `баржи`,
  `барий`,
  `бария`,
  `барка`,
  `барки`,
  `барна`,
  `барне`,
  `барни`,
  `барок`,
  `барон`,
  `барут`,
  `барче`,
  `барът`,
  `басет`,
  `баска`,
  `баски`,
  `баско`,
  `басма`,
  `басми`,
  `басни`,
  `басня`,
  `басов`,
  `басра`,
  `баста`,
  `басти`,
  `басът`,
  `батак`,
  `батал`,
  `батат`,
  `батик`,
  `батин`,
  `батко`,
  `батор`,
  `батут`,
  `батьо`,
  `бахаи`,
  `бахай`,
  `бахар`,
  `бахая`,
  `бахур`,
  `бахча`,
  `бахчи`,
  `бацил`,
  `бачев`,
  `бачия`,
  `бачка`,
  `башев`,
  `башка`,
  `бащин`,
  `баяна`,
  `баяно`,
  `бдели`,
  `бдене`,
  `бдеше`,
  `бдите`,
  `бдяла`,
  `бебов`,
  `бебче`,
  `бебчо`,
  `бегач`,
  `бегла`,
  `бегли`,
  `бегло`,
  `бегом`,
  `бегъл`,
  `бегът`,
  `беден`,
  `бедна`,
  `бедни`,
  `бедно`,
  `бедра`,
  `бедро`,
  `бежов`,
  `безир`,
  `безок`,
  `безух`,
  `бейов`,
  `бекар`,
  `бекас`,
  `беков`,
  `бекон`,
  `бекъп`,
  `бекът`,
  `бекяр`,
  `белач`,
  `белев`,
  `белег`,
  `белее`,
  `белей`,
  `белем`,
  `белен`,
  `белея`,
  `белил`,
  `белиш`,
  `белия`,
  `белка`,
  `белки`,
  `белот`,
  `белчо`,
  `бельо`,
  `белял`,
  `белят`,
  `бемол`,
  `бенда`,
  `бенин`,
  `бенка`,
  `бенки`,
  `бента`,
  `берат`,
  `берач`,
  `берем`,
  `береш`,
  `берил`,
  `беров`,
  `берта`,
  `берящ`,
  `бесел`,
  `бесен`,
  `бесещ`,
  `бесил`,
  `бесим`,
  `бесиш`,
  `бесни`,
  `бесът`,
  `бесят`,
  `бетер`,
  `бетон`,
  `бибоп`,
  `бивай`,
  `бивак`,
  `бивал`,
  `бивам`,
  `биват`,
  `биваш`,
  `бивни`,
  `бивол`,
  `бивша`,
  `бивше`,
  `бивши`,
  `бигор`,
  `бидон`,
  `биела`,
  `биели`,
  `биело`,
  `биене`,
  `биете`,
  `биеха`,
  `биеше`,
  `биеща`,
  `биещи`,
  `биещо`,
  `бижев`,
  `бизон`,
  `бийта`,
  `бийте`,
  `биков`,
  `бикче`,
  `бикът`,
  `билен`,
  `билет`,
  `билия`,
  `билка`,
  `билки`,
  `билна`,
  `билни`,
  `билно`,
  `билюк`,
  `билян`,
  `бинга`,
  `бинго`,
  `бинев`,
  `бином`,
  `бирен`,
  `бирма`,
  `бисау`,
  `бисер`,
  `битак`,
  `битие`,
  `битка`,
  `битки`,
  `битов`,
  `битум`,
  `битът`,
  `бихме`,
  `бихте`,
  `бичат`,
  `бичил`,
  `бичия`,
  `бичме`,
  `бичът`,
  `бияча`,
  `биячи`,
  `блага`,
  `благи`,
  `благо`,
  `блажа`,
  `блажи`,
  `блазе`,
  `блата`,
  `блато`,
  `бледа`,
  `бледи`,
  `бледо`,
  `бленд`,
  `блещи`,
  `блеят`,
  `ближа`,
  `ближе`,
  `ближи`,
  `близа`,
  `близо`,
  `блика`,
  `блинд`,
  `блини`,
  `блица`,
  `блога`,
  `блока`,
  `блуза`,
  `блузи`,
  `блуса`,
  `блюда`,
  `блюдо`,
  `бляна`,
  `боаза`,
  `боази`,
  `боата`,
  `бобев`,
  `бобен`,
  `бобец`,
  `бобов`,
  `бобри`,
  `бобче`,
  `бобър`,
  `бобът`,
  `богат`,
  `богът`,
  `бодат`,
  `бодва`,
  `бодеж`,
  `боден`,
  `бодил`,
  `бодка`,
  `бодли`,
  `бодна`,
  `бодне`,
  `бодни`,
  `бодра`,
  `бодри`,
  `бодро`,
  `бодър`,
  `боева`,
  `боеве`,
  `боеви`,
  `боево`,
  `боеца`,
  `боеше`,
  `божак`,
  `божан`,
  `божем`,
  `божие`,
  `божии`,
  `божий`,
  `божил`,
  `божин`,
  `божия`,
  `божке`,
  `божур`,
  `бозав`,
  `бозае`,
  `бозая`,
  `бозев`,
  `бозка`,
  `бозки`,
  `бозна`,
  `бозов`,
  `боинг`,
  `боите`,
  `бойка`,
  `бойки`,
  `бойко`,
  `бойна`,
  `бойни`,
  `бойно`,
  `бойсе`,
  `бойте`,
  `бойци`,
  `бойчо`,
  `бокал`,
  `боков`,
  `бокса`,
  `более`,
  `болен`,
  `болея`,
  `болид`,
  `болия`,
  `болка`,
  `болки`,
  `болна`,
  `болни`,
  `болно`,
  `болта`,
  `бомба`,
  `бомбе`,
  `бомби`,
  `бонев`,
  `бонин`,
  `бонка`,
  `бонов`,
  `бонус`,
  `бонче`,
  `бончо`,
  `борак`,
  `борба`,
  `борби`,
  `борда`,
  `бордо`,
  `борей`,
  `борел`,
  `борех`,
  `борец`,
  `борещ`,
  `борие`,
  `борий`,
  `борил`,
  `борим`,
  `борис`,
  `борих`,
  `бориш`,
  `боров`,
  `борса`,
  `борси`,
  `борци`,
  `борча`,
  `борче`,
  `борша`,
  `борът`,
  `борян`,
  `борят`,
  `босия`,
  `босна`,
  `босът`,
  `босяк`,
  `ботев`,
  `ботуш`,
  `ботът`,
  `ботьо`,
  `бохем`,
  `бохот`,
  `бохча`,
  `бохчи`,
  `боцев`,
  `боцка`,
  `боцна`,
  `бочев`,
  `бояла`,
  `бояли`,
  `бояна`,
  `боята`,
  `боящи`,
  `брава`,
  `брави`,
  `браво`,
  `брада`,
  `бради`,
  `брака`,
  `брала`,
  `брало`,
  `брана`,
  `бранд`,
  `бране`,
  `брани`,
  `брано`,
  `бранш`,
  `браня`,
  `браса`,
  `брата`,
  `брате`,
  `братя`,
  `браун`,
  `браха`,
  `брега`,
  `бреза`,
  `брезе`,
  `брези`,
  `брейк`,
  `бреме`,
  `бренд`,
  `брест`,
  `бридж`,
  `бриза`,
  `брича`,
  `брода`,
  `броди`,
  `бродя`,
  `броен`,
  `броец`,
  `броил`,
  `броим`,
  `броих`,
  `броиш`,
  `брома`,
  `бронз`,
  `брони`,
  `бронх`,
  `броня`,
  `броял`,
  `броят`,
  `броях`,
  `брояч`,
  `броящ`,
  `бруля`,
  `бруса`,
  `бруси`,
  `бруст`,
  `бруся`,
  `бруто`,
  `брънч`,
  `брюле`,
  `бряга`,
  `бряза`,
  `бряст`,
  `бубар`,
  `бубен`,
  `будел`,
  `буден`,
  `будещ`,
  `будил`,
  `будим`,
  `будиш`,
  `будка`,
  `будки`,
  `будна`,
  `будни`,
  `будно`,
  `будят`,
  `бузка`,
  `бузки`,
  `буика`,
  `буйна`,
  `буйни`,
  `буйно`,
  `букаи`,
  `букак`,
  `буква`,
  `букви`,
  `букет`,
  `букла`,
  `букле`,
  `букна`,
  `буков`,
  `букса`,
  `букът`,
  `булдо`,
  `булев`,
  `булка`,
  `булки`,
  `булче`,
  `бумка`,
  `бумтя`,
  `бумът`,
  `бунак`,
  `бунар`,
  `буная`,
  `бунен`,
  `бунта`,
  `бунят`,
  `бурен`,
  `бурии`,
  `бурия`,
  `бурка`,
  `бурки`,
  `бурма`,
  `бурми`,
  `бурна`,
  `бурни`,
  `бурно`,
  `буров`,
  `бурса`,
  `бусът`,
  `бутай`,
  `бутал`,
  `бутам`,
  `бутан`,
  `бутат`,
  `буташ`,
  `бутва`,
  `бутик`,
  `бутна`,
  `бутне`,
  `бутон`,
  `бутче`,
  `буфер`,
  `бухай`,
  `бухал`,
  `бухам`,
  `бухан`,
  `бухна`,
  `бухне`,
  `бухта`,
  `бухти`,
  `бухтя`,
  `бучен`,
  `бучил`,
  `бучим`,
  `бучин`,
  `бучка`,
  `бучки`,
  `бушел`,
  `бушон`,
  `бъбля`,
  `бъбри`,
  `бъбря`,
  `бъгът`,
  `бъдат`,
  `бъдел`,
  `бъдем`,
  `бъден`,
  `бъдех`,
  `бъдеш`,
  `бъдещ`,
  `бъдни`,
  `бъзак`,
  `бъзик`,
  `бъзла`,
  `бъзне`,
  `бъзов`,
  `бъзън`,
  `бъзът`,
  `бъкай`,
  `бъкам`,
  `бъкан`,
  `бъкат`,
  `бъкел`,
  `бълва`,
  `бълха`,
  `бълхи`,
  `бънев`,
  `бърда`,
  `бърдо`,
  `бърже`,
  `бърза`,
  `бързи`,
  `бързо`,
  `бърка`,
  `бърля`,
  `бърна`,
  `бърни`,
  `бърса`,
  `бърча`,
  `бърша`,
  `бърше`,
  `бъхти`,
  `бъхтя`,
  `бъчва`,
  `бъчви`,
  `бюрек`,
  `бюста`,
  `бюфет`,
  `бягай`,
  `бягал`,
  `бягам`,
  `бягат`,
  `бягаш`,
  `бягащ`,
  `бялка`,
  `бясна`,
  `бясно`,
  `вагон`,
  `вагус`,
  `вадел`,
  `ваден`,
  `вадещ`,
  `вадил`,
  `вадим`,
  `вадин`,
  `вадиш`,
  `вадуц`,
  `вадят`,
  `ваене`,
  `важал`,
  `важат`,
  `важен`,
  `важиш`,
  `важна`,
  `важни`,
  `важно`,
  `вазов`,
  `вакла`,
  `вакли`,
  `вакло`,
  `вакса`,
  `вакъл`,
  `вакъф`,
  `валеж`,
  `валии`,
  `валим`,
  `валиш`,
  `валия`,
  `валма`,
  `валмо`,
  `валог`,
  `валса`,
  `валче`,
  `валът`,
  `вальо`,
  `валяк`,
  `валял`,
  `валям`,
  `валят`,
  `валящ`,
  `ванев`,
  `ванко`,
  `ванчо`,
  `ванът`,
  `ваньо`,
  `варак`,
  `варан`,
  `варда`,
  `варди`,
  `вардя`,
  `варел`,
  `варен`,
  `варил`,
  `варим`,
  `вариш`,
  `варна`,
  `варов`,
  `варта`,
  `варус`,
  `варяг`,
  `варял`,
  `варят`,
  `васал`,
  `васев`,
  `васил`,
  `васка`,
  `васко`,
  `ватен`,
  `ватът`,
  `вафла`,
  `вафли`,
  `вахта`,
  `вацев`,
  `вачев`,
  `вашия`,
  `ваяна`,
  `ваяне`,
  `ваяни`,
  `ваяно`,
  `вбеси`,
  `вбеся`,
  `вбива`,
  `вбити`,
  `вгъва`,
  `вгъна`,
  `вдава`,
  `вдали`,
  `вдене`,
  `вдени`,
  `вдига`,
  `вдиша`,
  `вдява`,
  `вдяна`,
  `вегас`,
  `ведно`,
  `ведра`,
  `ведри`,
  `ведро`,
  `ведър`,
  `веела`,
  `веели`,
  `веело`,
  `веене`,
  `вееха`,
  `вееше`,
  `вежда`,
  `вежди`,
  `везан`,
  `везба`,
  `везел`,
  `везем`,
  `везир`,
  `везмо`,
  `везна`,
  `везни`,
  `вейка`,
  `вейки`,
  `вейни`,
  `веков`,
  `векът`,
  `велев`,
  `велес`,
  `велик`,
  `велин`,
  `велия`,
  `велко`,
  `велур`,
  `велчо`,
  `вельо`,
  `велян`,
  `венев`,
  `венец`,
  `венка`,
  `венко`,
  `венци`,
  `венча`,
  `венче`,
  `вепър`,
  `верде`,
  `верев`,
  `верен`,
  `верея`,
  `верни`,
  `верую`,
  `весел`,
  `весен`,
  `веска`,
  `веско`,
  `весла`,
  `весло`,
  `весни`,
  `весов`,
  `вести`,
  `ветре`,
  `ветри`,
  `ветря`,
  `вехна`,
  `вехне`,
  `вехта`,
  `вехти`,
  `вехто`,
  `вечен`,
  `вечер`,
  `вечна`,
  `вечни`,
  `вечно`,
  `вещае`,
  `вещая`,
  `вещен`,
  `вещер`,
  `вещия`,
  `вещна`,
  `вещни`,
  `вещно`,
  `вещта`,
  `взвод`,
  `взела`,
  `взели`,
  `взело`,
  `взема`,
  `вземе`,
  `вземи`,
  `взета`,
  `взети`,
  `взето`,
  `взеха`,
  `взима`,
  `взира`,
  `взлом`,
  `взора`,
  `взреш`,
  `взрив`,
  `вивей`,
  `видеа`,
  `видев`,
  `видел`,
  `виден`,
  `видео`,
  `видех`,
  `видим`,
  `видин`,
  `видиш`,
  `видна`,
  `видни`,
  `видно`,
  `видов`,
  `видра`,
  `видри`,
  `видът`,
  `видял`,
  `видян`,
  `видят`,
  `видях`,
  `виево`,
  `виела`,
  `виели`,
  `виело`,
  `виена`,
  `виене`,
  `виеха`,
  `виеща`,
  `виещи`,
  `вижда`,
  `вижте`,
  `визии`,
  `визия`,
  `визов`,
  `визон`,
  `викай`,
  `викал`,
  `викам`,
  `викан`,
  `викат`,
  `виках`,
  `викач`,
  `викаш`,
  `виква`,
  `викна`,
  `викне`,
  `викни`,
  `викът`,
  `вилен`,
  `вилия`,
  `вилка`,
  `вилна`,
  `вилни`,
  `вилно`,
  `вилня`,
  `винар`,
  `винен`,
  `винил`,
  `виним`,
  `виниш`,
  `винта`,
  `винце`,
  `винял`,
  `винят`,
  `виола`,
  `виоли`,
  `вираж`,
  `вирва`,
  `вирее`,
  `вирен`,
  `вирея`,
  `вирна`,
  `вирус`,
  `вирче`,
  `вирът`,
  `висва`,
  `висиш`,
  `висла`,
  `висна`,
  `висне`,
  `висок`,
  `висон`,
  `виста`,
  `висша`,
  `висше`,
  `висши`,
  `висял`,
  `висят`,
  `висях`,
  `висящ`,
  `витае`,
  `витал`,
  `витан`,
  `витая`,
  `вития`,
  `витла`,
  `витло`,
  `витро`,
  `витяз`,
  `вихри`,
  `вихър`,
  `вицът`,
  `вичев`,
  `вишан`,
  `вишка`,
  `вишки`,
  `вишна`,
  `вишни`,
  `вишня`,
  `вкара`,
  `вкова`,
  `вкуса`,
  `вкуси`,
  `вкуся`,
  `вкъщи`,
  `влага`,
  `владо`,
  `владя`,
  `влаев`,
  `влака`,
  `власи`,
  `власт`,
  `влаха`,
  `влахи`,
  `влача`,
  `влачи`,
  `вледя`,
  `влеем`,
  `влезе`,
  `влека`,
  `влели`,
  `влети`,
  `влетя`,
  `влече`,
  `влечи`,
  `влеят`,
  `влива`,
  `влиза`,
  `влита`,
  `влияе`,
  `влияя`,
  `вложа`,
  `вложи`,
  `влоша`,
  `влоши`,
  `влюби`,
  `влюбя`,
  `вляво`,
  `вляза`,
  `вляла`,
  `вляло`,
  `влята`,
  `влято`,
  `вляха`,
  `вмени`,
  `вменя`,
  `вмеся`,
  `вмета`,
  `вмята`,
  `внася`,
  `внеса`,
  `внесе`,
  `внеси`,
  `вноса`,
  `внука`,
  `внуци`,
  `внуче`,
  `внуша`,
  `внуши`,
  `воала`,
  `воали`,
  `воаяж`,
  `водач`,
  `водел`,
  `воден`,
  `водех`,
  `водещ`,
  `водил`,
  `водим`,
  `водих`,
  `водиш`,
  `водка`,
  `водки`,
  `водна`,
  `водни`,
  `водно`,
  `водят`,
  `воеве`,
  `вожда`,
  `возач`,
  `возел`,
  `возен`,
  `возещ`,
  `возил`,
  `возим`,
  `возиш`,
  `возят`,
  `воина`,
  `воини`,
  `воище`,
  `война`,
  `войни`,
  `вокал`,
  `вокил`,
  `волан`,
  `волва`,
  `волво`,
  `волга`,
  `волги`,
  `волев`,
  `волен`,
  `волна`,
  `волни`,
  `волно`,
  `волта`,
  `волът`,
  `вонещ`,
  `вонея`,
  `вонът`,
  `вопли`,
  `вопъл`,
  `восък`,
  `вотът`,
  `воюва`,
  `вперя`,
  `впива`,
  `впила`,
  `впили`,
  `впиша`,
  `впише`,
  `впият`,
  `вплел`,
  `впряг`,
  `врага`,
  `вража`,
  `вражи`,
  `врана`,
  `врани`,
  `врано`,
  `враня`,
  `врата`,
  `врати`,
  `враца`,
  `врача`,
  `вреда`,
  `вреди`,
  `вредя`,
  `врежа`,
  `вреже`,
  `врежи`,
  `врели`,
  `врело`,
  `време`,
  `врене`,
  `врече`,
  `врещя`,
  `врича`,
  `вродя`,
  `връча`,
  `връчи`,
  `връща`,
  `врява`,
  `вряза`,
  `вряла`,
  `вряло`,
  `вряща`,
  `врящи`,
  `врящо`,
  `всадя`,
  `всека`,
  `всеки`,
  `всели`,
  `вселя`,
  `всече`,
  `всечи`,
  `всеят`,
  `всича`,
  `всред`,
  `всъде`,
  `всява`,
  `всяка`,
  `всяко`,
  `всяло`,
  `всята`,
  `втаса`,
  `втека`,
  `втича`,
  `втора`,
  `втори`,
  `второ`,
  `втрия`,
  `втъка`,
  `втъче`,
  `втъчи`,
  `вуйна`,
  `вуйчо`,
  `вукан`,
  `вулва`,
  `вурст`,
  `вутев`,
  `вутов`,
  `вуцов`,
  `входа`,
  `вчера`,
  `вчета`,
  `вчеша`,
  `вшити`,
  `въбел`,
  `въвел`,
  `въвра`,
  `въвре`,
  `въври`,
  `въвря`,
  `въдят`,
  `въжар`,
  `въжен`,
  `възел`,
  `въззе`,
  `възли`,
  `вълев`,
  `вълка`,
  `вълко`,
  `вълна`,
  `вълни`,
  `вълов`,
  `вълци`,
  `вълча`,
  `вълче`,
  `вълчи`,
  `вълчо`,
  `вънка`,
  `върба`,
  `върби`,
  `върви`,
  `вървя`,
  `вържа`,
  `върже`,
  `вържи`,
  `върза`,
  `върла`,
  `върли`,
  `върло`,
  `върна`,
  `върне`,
  `върни`,
  `върти`,
  `въртя`,
  `върха`,
  `върху`,
  `върша`,
  `върши`,
  `вътев`,
  `вътов`,
  `вътор`,
  `вътре`,
  `вътък`,
  `въцов`,
  `въчев`,
  `въшка`,
  `въшки`,
  `въшла`,
  `вярва`,
  `вярна`,
  `вярно`,
  `вясна`,
  `вятър`,
  `габер`,
  `габон`,
  `габра`,
  `габър`,
  `гавот`,
  `гавра`,
  `гаври`,
  `гадае`,
  `гадах`,
  `гадая`,
  `гаден`,
  `гадже`,
  `гадил`,
  `гадиш`,
  `гадна`,
  `гадни`,
  `гадно`,
  `газел`,
  `газен`,
  `газим`,
  `газиш`,
  `газка`,
  `газки`,
  `газов`,
  `газта`,
  `газът`,
  `газят`,
  `гайда`,
  `гайди`,
  `гайка`,
  `гайки`,
  `гайле`,
  `галев`,
  `гален`,
  `галещ`,
  `галий`,
  `галил`,
  `галим`,
  `галин`,
  `галиш`,
  `галия`,
  `галон`,
  `галоп`,
  `галош`,
  `галят`,
  `гамаш`,
  `гамба`,
  `гамби`,
  `гамен`,
  `ганди`,
  `ганев`,
  `ганка`,
  `ганчо`,
  `ганьо`,
  `гараж`,
  `гарга`,
  `гарги`,
  `гарда`,
  `гарев`,
  `гаров`,
  `гасен`,
  `гасна`,
  `гасне`,
  `гасни`,
  `гасял`,
  `гасят`,
  `гасящ`,
  `гатев`,
  `гатер`,
  `гатов`,
  `гауда`,
  `гауди`,
  `гаучо`,
  `гафът`,
  `гацев`,
  `гачев`,
  `гащат`,
  `гаяна`,
  `гдето`,
  `геена`,
  `гейма`,
  `гейта`,
  `гейша`,
  `гейши`,
  `гелев`,
  `гелът`,
  `гемии`,
  `гемия`,
  `генен`,
  `гении`,
  `гений`,
  `гения`,
  `генко`,
  `генна`,
  `генни`,
  `генно`,
  `генов`,
  `геном`,
  `генуа`,
  `генчо`,
  `генът`,
  `гепид`,
  `герак`,
  `геран`,
  `герба`,
  `герен`,
  `геров`,
  `герои`,
  `герой`,
  `герон`,
  `герою`,
  `героя`,
  `гетов`,
  `гетри`,
  `гецов`,
  `гечев`,
  `гешев`,
  `гибел`,
  `гибон`,
  `гибус`,
  `гиген`,
  `гигов`,
  `гидия`,
  `гизда`,
  `гизди`,
  `гиздя`,
  `гилда`,
  `гилев`,
  `гилза`,
  `гилзи`,
  `гинат`,
  `гинев`,
  `гинес`,
  `гинин`,
  `гинка`,
  `гинко`,
  `гинци`,
  `гинчо`,
  `гиньо`,
  `гипса`,
  `гичев`,
  `гичка`,
  `глава`,
  `глави`,
  `главо`,
  `главя`,
  `глада`,
  `глади`,
  `гладя`,
  `гланц`,
  `гласа`,
  `гласи`,
  `глася`,
  `гледа`,
  `глези`,
  `глезя`,
  `глиги`,
  `глина`,
  `глини`,
  `глист`,
  `глоба`,
  `глоби`,
  `глобя`,
  `глога`,
  `глоса`,
  `глоси`,
  `глума`,
  `глуми`,
  `глуха`,
  `глухи`,
  `глухо`,
  `глуша`,
  `гнайс`,
  `гнева`,
  `гневи`,
  `гневя`,
  `гнета`,
  `гнети`,
  `гнетя`,
  `гнида`,
  `гниещ`,
  `гнила`,
  `гнили`,
  `гнило`,
  `гният`,
  `гноен`,
  `гнома`,
  `гноми`,
  `гнуси`,
  `гнява`,
  `говее`,
  `говея`,
  `говно`,
  `говор`,
  `говял`,
  `гогов`,
  `гогол`,
  `годеж`,
  `годен`,
  `годеч`,
  `годил`,
  `годиш`,
  `годна`,
  `годни`,
  `годно`,
  `гоева`,
  `гозба`,
  `гозби`,
  `голак`,
  `голем`,
  `голец`,
  `голеш`,
  `голея`,
  `голия`,
  `голов`,
  `голфа`,
  `голът`,
  `голям`,
  `гонга`,
  `гонел`,
  `гонен`,
  `гонех`,
  `гонил`,
  `гоним`,
  `гониш`,
  `гонка`,
  `гонки`,
  `гонче`,
  `гонят`,
  `горан`,
  `горда`,
  `горди`,
  `гордо`,
  `горен`,
  `горещ`,
  `горим`,
  `гориш`,
  `горка`,
  `горки`,
  `горко`,
  `горна`,
  `горни`,
  `горно`,
  `горов`,
  `горун`,
  `горча`,
  `горчи`,
  `горък`,
  `горял`,
  `горян`,
  `горят`,
  `горящ`,
  `госта`,
  `госте`,
  `гости`,
  `гостя`,
  `готви`,
  `готвя`,
  `готик`,
  `готин`,
  `готов`,
  `готът`,
  `гоцев`,
  `гочев`,
  `гошев`,
  `граал`,
  `граби`,
  `грабя`,
  `града`,
  `гради`,
  `градя`,
  `грама`,
  `грами`,
  `гранд`,
  `грант`,
  `граня`,
  `грапа`,
  `грапи`,
  `грапя`,
  `графа`,
  `графе`,
  `графи`,
  `граха`,
  `грача`,
  `грачи`,
  `греба`,
  `гребе`,
  `греда`,
  `греди`,
  `грени`,
  `греси`,
  `грета`,
  `грети`,
  `греха`,
  `греша`,
  `греши`,
  `греян`,
  `греят`,
  `гриба`,
  `грива`,
  `гриви`,
  `грижа`,
  `грижи`,
  `гриза`,
  `гризе`,
  `гризи`,
  `гризу`,
  `грила`,
  `грима`,
  `грипа`,
  `грифа`,
  `гриша`,
  `гроба`,
  `гроги`,
  `грозд`,
  `грози`,
  `грозя`,
  `громи`,
  `громя`,
  `гроса`,
  `гроси`,
  `грота`,
  `гроша`,
  `груба`,
  `груби`,
  `грубо`,
  `груев`,
  `грунд`,
  `грунт`,
  `група`,
  `групи`,
  `груув`,
  `груха`,
  `гръма`,
  `грюер`,
  `гряха`,
  `губел`,
  `губен`,
  `губер`,
  `губеш`,
  `губещ`,
  `губил`,
  `губим`,
  `губиш`,
  `губят`,
  `гугла`,
  `гугли`,
  `гугов`,
  `гугол`,
  `гугъл`,
  `гузен`,
  `гузна`,
  `гузни`,
  `гузно`,
  `гукай`,
  `гукам`,
  `гукат`,
  `гулаш`,
  `гулев`,
  `гулия`,
  `гуляи`,
  `гуляй`,
  `гулял`,
  `гуляя`,
  `гумен`,
  `гумна`,
  `гумно`,
  `гунев`,
  `гурел`,
  `гурко`,
  `гурме`,
  `гусев`,
  `гусла`,
  `гусли`,
  `гуцал`,
  `гушав`,
  `гушен`,
  `гушка`,
  `гушна`,
  `гущер`,
  `гъбав`,
  `гъбар`,
  `гъбен`,
  `гъбка`,
  `гъбна`,
  `гъбни`,
  `гъбно`,
  `гъвко`,
  `гъвък`,
  `гъгна`,
  `гъдев`,
  `гъдел`,
  `гъжва`,
  `гъкам`,
  `гълта`,
  `гълча`,
  `гълчи`,
  `гълъб`,
  `гъмжа`,
  `гъмжи`,
  `гъмза`,
  `гъмзи`,
  `гънел`,
  `гънка`,
  `гънки`,
  `гърба`,
  `гърби`,
  `гърда`,
  `гърди`,
  `гърка`,
  `гърко`,
  `гърла`,
  `гърли`,
  `гърло`,
  `гърля`,
  `гърма`,
  `гърми`,
  `гърмя`,
  `гърне`,
  `гърня`,
  `гърци`,
  `гърча`,
  `гърчи`,
  `гъсар`,
  `гъска`,
  `гъски`,
  `гъсок`,
  `гъста`,
  `гъсти`,
  `гъсто`,
  `гътам`,
  `гътна`,
  `гьола`,
  `гьона`,
  `гьоте`,
  `гюбре`,
  `гювеч`,
  `гюлев`,
  `гюлле`,
  `гюлов`,
  `гюров`,
  `гюрук`,
  `гяури`,
  `давай`,
  `давал`,
  `давам`,
  `даван`,
  `дават`,
  `давах`,
  `даваш`,
  `даващ`,
  `давещ`,
  `давид`,
  `давим`,
  `давия`,
  `давят`,
  `дадат`,
  `дадем`,
  `даден`,
  `дадеш`,
  `дадох`,
  `дадял`,
  `дадях`,
  `дажба`,
  `дажби`,
  `дайре`,
  `дайте`,
  `дакар`,
  `дакел`,
  `даков`,
  `далай`,
  `далак`,
  `далас`,
  `далеч`,
  `далии`,
  `далия`,
  `дамар`,
  `дамба`,
  `дамга`,
  `дамян`,
  `дамят`,
  `данак`,
  `данев`,
  `дания`,
  `данка`,
  `данни`,
  `данов`,
  `данте`,
  `данчо`,
  `данък`,
  `дарак`,
  `дарба`,
  `дарби`,
  `дарен`,
  `дарил`,
  `дарим`,
  `дарих`,
  `дариш`,
  `дария`,
  `даром`,
  `дартс`,
  `дарът`,
  `дарял`,
  `дарят`,
  `дафин`,
  `дафов`,
  `дачев`,
  `дачии`,
  `дачия`,
  `дашен`,
  `двама`,
  `двата`,
  `двери`,
  `двете`,
  `движа`,
  `движи`,
  `двина`,
  `двоен`,
  `двоих`,
  `двора`,
  `двояк`,
  `дебат`,
  `дебел`,
  `дебил`,
  `дебит`,
  `дебна`,
  `дебне`,
  `дебни`,
  `дебра`,
  `дебри`,
  `дебют`,
  `девер`,
  `девет`,
  `девиз`,
  `девин`,
  `девня`,
  `дедец`,
  `дееца`,
  `дейли`,
  `дейна`,
  `дейни`,
  `дейно`,
  `дейци`,
  `декан`,
  `декар`,
  `деков`,
  `декор`,
  `делба`,
  `делби`,
  `делва`,
  `делви`,
  `делев`,
  `дележ`,
  `делен`,
  `делил`,
  `делим`,
  `делиш`,
  `делия`,
  `делко`,
  `делне`,
  `делни`,
  `делов`,
  `делта`,
  `делти`,
  `делфи`,
  `делхи`,
  `делчо`,
  `делът`,
  `делял`,
  `делян`,
  `делят`,
  `делящ`,
  `демек`,
  `демон`,
  `демос`,
  `денар`,
  `денди`,
  `денев`,
  `денел`,
  `денем`,
  `денех`,
  `денеш`,
  `деним`,
  `денис`,
  `денка`,
  `денчо`,
  `деньо`,
  `денят`,
  `дерат`,
  `дерби`,
  `дереш`,
  `дерма`,
  `дерта`,
  `десен`,
  `десет`,
  `десни`,
  `дефис`,
  `дечев`,
  `дечко`,
  `деяна`,
  `джава`,
  `джави`,
  `джаги`,
  `джаза`,
  `джама`,
  `джапа`,
  `джаул`,
  `джема`,
  `джета`,
  `джина`,
  `джинс`,
  `джипа`,
  `джипи`,
  `джира`,
  `джиро`,
  `джоба`,
  `джубе`,
  `джуда`,
  `джудо`,
  `джука`,
  `джуки`,
  `джуни`,
  `джуно`,
  `дзифт`,
  `диана`,
  `дибич`,
  `дивак`,
  `диван`,
  `дивей`,
  `дивен`,
  `дивеч`,
  `дивея`,
  `дивил`,
  `дивия`,
  `дивля`,
  `дивна`,
  `дивни`,
  `дивно`,
  `дивят`,
  `дигал`,
  `дигам`,
  `дигат`,
  `дигна`,
  `дигне`,
  `дигни`,
  `диева`,
  `диеза`,
  `диези`,
  `диета`,
  `диети`,
  `дизел`,
  `дикиш`,
  `диков`,
  `дилаф`,
  `дилов`,
  `дилър`,
  `димен`,
  `димка`,
  `димки`,
  `димна`,
  `димни`,
  `димно`,
  `димов`,
  `димча`,
  `димчо`,
  `димът`,
  `димят`,
  `динар`,
  `динго`,
  `динев`,
  `динен`,
  `динка`,
  `динко`,
  `диньо`,
  `диода`,
  `диоди`,
  `дипла`,
  `дипли`,
  `дипля`,
  `дипол`,
  `дирек`,
  `дирен`,
  `дирим`,
  `дирих`,
  `дириш`,
  `дирят`,
  `диска`,
  `диско`,
  `дичев`,
  `дичин`,
  `дишай`,
  `дишал`,
  `дишам`,
  `дишан`,
  `дишат`,
  `дишаш`,
  `длани`,
  `длета`,
  `длето`,
  `дните`,
  `добив`,
  `добие`,
  `добий`,
  `добил`,
  `добит`,
  `добих`,
  `добия`,
  `добле`,
  `добра`,
  `добре`,
  `добри`,
  `добро`,
  `добър`,
  `довее`,
  `довел`,
  `довея`,
  `довил`,
  `довия`,
  `довод`,
  `догма`,
  `догми`,
  `догът`,
  `додам`,
  `додев`,
  `додея`,
  `додов`,
  `доели`,
  `доене`,
  `доеше`,
  `дожът`,
  `дойда`,
  `дойде`,
  `дойди`,
  `дойка`,
  `дойки`,
  `дойна`,
  `дойни`,
  `дойно`,
  `дойте`,
  `докер`,
  `докла`,
  `доков`,
  `докъм`,
  `докът`,
  `долап`,
  `долар`,
  `долби`,
  `долен`,
  `долец`,
  `долея`,
  `долие`,
  `долма`,
  `долна`,
  `долни`,
  `долно`,
  `долче`,
  `долът`,
  `долян`,
  `домат`,
  `домие`,
  `домия`,
  `домля`,
  `домът`,
  `донев`,
  `донка`,
  `донов`,
  `донор`,
  `донос`,
  `дончо`,
  `донът`,
  `доньо`,
  `допея`,
  `допил`,
  `допир`,
  `допия`,
  `допра`,
  `допре`,
  `допря`,
  `досам`,
  `досев`,
  `досег`,
  `досей`,
  `досея`,
  `досие`,
  `доспа`,
  `доспи`,
  `доспя`,
  `доста`,
  `досущ`,
  `дотам`,
  `дотев`,
  `дотук`,
  `доуби`,
  `доуча`,
  `дохио`,
  `доход`,
  `доцов`,
  `дочев`,
  `дочел`,
  `дочен`,
  `дочка`,
  `дочул`,
  `дочут`,
  `дочуя`,
  `дошла`,
  `дошли`,
  `дошло`,
  `дошъл`,
  `дояде`,
  `доячи`,
  `драва`,
  `драга`,
  `драги`,
  `драго`,
  `драже`,
  `драйв`,
  `драка`,
  `драки`,
  `драли`,
  `драма`,
  `драми`,
  `дране`,
  `драни`,
  `драно`,
  `драпа`,
  `драфт`,
  `дращи`,
  `дращя`,
  `дрейф`,
  `дрема`,
  `дреме`,
  `дреми`,
  `дремя`,
  `дреха`,
  `дрехи`,
  `дрина`,
  `дрипа`,
  `дрипи`,
  `дроба`,
  `дроби`,
  `дробя`,
  `дрога`,
  `дроги`,
  `дрозд`,
  `дроид`,
  `дропс`,
  `друга`,
  `други`,
  `друго`,
  `дружа`,
  `дружи`,
  `друза`,
  `друзи`,
  `друид`,
  `друма`,
  `друми`,
  `друса`,
  `дръма`,
  `дряна`,
  `дуала`,
  `дубли`,
  `дубъл`,
  `дувар`,
  `дудна`,
  `дудук`,
  `дуела`,
  `дуели`,
  `дуета`,
  `дуети`,
  `дузпа`,
  `дузпи`,
  `дукат`,
  `дуков`,
  `дукът`,
  `дулап`,
  `думай`,
  `думал`,
  `думам`,
  `думан`,
  `думат`,
  `думба`,
  `думка`,
  `думна`,
  `дунав`,
  `дунда`,
  `дунди`,
  `дунев`,
  `дунов`,
  `дуото`,
  `дупка`,
  `дупки`,
  `дупча`,
  `дупчи`,
  `дупят`,
  `дурак`,
  `дурча`,
  `духай`,
  `духам`,
  `духан`,
  `духат`,
  `духач`,
  `духащ`,
  `духва`,
  `духна`,
  `духне`,
  `духов`,
  `духом`,
  `духче`,
  `духът`,
  `душат`,
  `душен`,
  `душещ`,
  `душка`,
  `душко`,
  `душно`,
  `душов`,
  `душче`,
  `душът`,
  `дъбак`,
  `дъбен`,
  `дъбов`,
  `дъбът`,
  `дъвка`,
  `дъвки`,
  `дъвча`,
  `дъвче`,
  `дъвчи`,
  `дъгов`,
  `дъжда`,
  `дълга`,
  `дълги`,
  `дълго`,
  `дължа`,
  `дължи`,
  `дълъг`,
  `дънен`,
  `дънер`,
  `дъниш`,
  `дънки`,
  `дънна`,
  `дънни`,
  `дънно`,
  `дънов`,
  `дънце`,
  `дърва`,
  `дърви`,
  `дърво`,
  `държа`,
  `държи`,
  `дърма`,
  `дърпа`,
  `дърти`,
  `дъска`,
  `дъски`,
  `дъхав`,
  `дъхам`,
  `дъхва`,
  `дъхна`,
  `дъхът`,
  `дюбел`,
  `дюйма`,
  `дюкян`,
  `дюлев`,
  `дюнер`,
  `дюшек`,
  `дявам`,
  `дявол`,
  `дядка`,
  `дядов`,
  `дяков`,
  `дякон`,
  `дялам`,
  `дялан`,
  `дялат`,
  `дялка`,
  `дялна`,
  `дялов`,
  `дялък`,
  `дялът`,
  `дянал`,
  `дянат`,
  `дянах`,
  `дянко`,
  `дясна`,
  `дясно`,
  `ебава`,
  `ебала`,
  `ебана`,
  `ебане`,
  `ебола`,
  `еболи`,
  `евала`,
  `евата`,
  `евбея`,
  `евина`,
  `евите`,
  `евнух`,
  `евреи`,
  `евров`,
  `евтим`,
  `евтин`,
  `егида`,
  `егота`,
  `егото`,
  `егрек`,
  `едвам`,
  `едикт`,
  `еднаж`,
  `едрев`,
  `едрее`,
  `едрея`,
  `едрия`,
  `едров`,
  `ежова`,
  `ежово`,
  `ездач`,
  `езера`,
  `езеро`,
  `езика`,
  `езици`,
  `езиче`,
  `езуит`,
  `ейджа`,
  `ейлът`,
  `ейпъл`,
  `екали`,
  `екане`,
  `еквам`,
  `екипа`,
  `екипи`,
  `еклер`,
  `еклив`,
  `екнал`,
  `екове`,
  `екота`,
  `екран`,
  `ексик`,
  `експо`,
  `екшън`,
  `елада`,
  `елата`,
  `елека`,
  `елена`,
  `елени`,
  `елеци`,
  `елече`,
  `елзас`,
  `елиза`,
  `елина`,
  `елини`,
  `елита`,
  `елите`,
  `елити`,
  `елица`,
  `елмаз`,
  `елова`,
  `елови`,
  `елово`,
  `елфът`,
  `елхов`,
  `емайл`,
  `емвам`,
  `емине`,
  `емира`,
  `емири`,
  `емляк`,
  `емнал`,
  `емона`,
  `емута`,
  `емфие`,
  `енева`,
  `енево`,
  `ензим`,
  `енина`,
  `еница`,
  `енота`,
  `еноти`,
  `ентри`,
  `енфие`,
  `енчев`,
  `енчец`,
  `еньов`,
  `еолит`,
  `еоцен`,
  `епика`,
  `епоса`,
  `епоси`,
  `епоха`,
  `епохи`,
  `ерата`,
  `ербап`,
  `ербий`,
  `ерген`,
  `ерден`,
  `ереси`,
  `ерзац`,
  `ерите`,
  `еркер`,
  `ерова`,
  `ерови`,
  `ерово`,
  `ероса`,
  `есени`,
  `есета`,
  `есето`,
  `ескиз`,
  `еснаф`,
  `естер`,
  `естет`,
  `етажа`,
  `етажи`,
  `етана`,
  `етапа`,
  `етапи`,
  `етера`,
  `етери`,
  `етика`,
  `етнос`,
  `етъра`,
  `етюда`,
  `етюди`,
  `ефект`,
  `ефеса`,
  `ефира`,
  `ефрат`,
  `ефрем`,
  `ехото`,
  `ехтеж`,
  `ехтял`,
  `ехтят`,
  `ехтящ`,
  `ечаха`,
  `ечащи`,
  `ечене`,
  `ечете`,
  `жабар`,
  `жабка`,
  `жабки`,
  `жабок`,
  `жабче`,
  `жаден`,
  `жадна`,
  `жадни`,
  `жадно`,
  `жажда`,
  `жакет`,
  `жалба`,
  `жалби`,
  `жалва`,
  `жалей`,
  `жалел`,
  `жален`,
  `жалея`,
  `жалим`,
  `жалка`,
  `жалки`,
  `жалко`,
  `жална`,
  `жални`,
  `жално`,
  `жалон`,
  `жалуз`,
  `жалък`,
  `жалят`,
  `жанет`,
  `жанра`,
  `жарка`,
  `жарки`,
  `жарко`,
  `жарсе`,
  `жарта`,
  `жарък`,
  `жегла`,
  `жегна`,
  `жегне`,
  `жегъл`,
  `жедна`,
  `жежка`,
  `жежки`,
  `жежко`,
  `жежък`,
  `жезли`,
  `жезъл`,
  `жеков`,
  `желае`,
  `желай`,
  `желал`,
  `желан`,
  `желах`,
  `желая`,
  `желев`,
  `желен`,
  `желъд`,
  `жельо`,
  `женда`,
  `женел`,
  `женен`,
  `женил`,
  `женим`,
  `жених`,
  `жениш`,
  `женят`,
  `жерав`,
  `жерка`,
  `жеста`,
  `жетва`,
  `жетон`,
  `жечев`,
  `живак`,
  `живее`,
  `живей`,
  `живец`,
  `живея`,
  `живия`,
  `живка`,
  `живко`,
  `живне`,
  `живот`,
  `живущ`,
  `живци`,
  `живял`,
  `живян`,
  `живях`,
  `жидов`,
  `жиков`,
  `жилав`,
  `жилен`,
  `жилет`,
  `жилиш`,
  `жилка`,
  `жилки`,
  `жилна`,
  `жилни`,
  `жилят`,
  `жираф`,
  `житар`,
  `жител`,
  `житен`,
  `житие`,
  `жития`,
  `житна`,
  `житни`,
  `житно`,
  `житце`,
  `жичен`,
  `жичка`,
  `жички`,
  `жична`,
  `жични`,
  `жично`,
  `жлеба`,
  `жлеза`,
  `жлези`,
  `жокеи`,
  `жокей`,
  `жокер`,
  `жокея`,
  `жоров`,
  `жреца`,
  `жреци`,
  `жрица`,
  `жрици`,
  `жуанг`,
  `жужащ`,
  `жулен`,
  `жулна`,
  `жумиш`,
  `жупан`,
  `жупел`,
  `жълта`,
  `жълти`,
  `жълто`,
  `жълтя`,
  `жълъд`,
  `жънат`,
  `жънел`,
  `жънеш`,
  `жънещ`,
  `жътва`,
  `жътви`,
  `забел`,
  `забие`,
  `забий`,
  `забил`,
  `забит`,
  `забих`,
  `забия`,
  `забой`,
  `забол`,
  `забоя`,
  `завел`,
  `завет`,
  `завея`,
  `завзе`,
  `завие`,
  `завиж`,
  `завий`,
  `завил`,
  `завит`,
  `завия`,
  `завод`,
  `завои`,
  `завой`,
  `завоя`,
  `завра`,
  `завре`,
  `заври`,
  `загар`,
  `загоя`,
  `задай`,
  `задал`,
  `задам`,
  `заден`,
  `задна`,
  `задни`,
  `задно`,
  `задой`,
  `задоя`,
  `задух`,
  `задуя`,
  `заебе`,
  `заека`,
  `заела`,
  `заели`,
  `заело`,
  `заема`,
  `заеме`,
  `заеми`,
  `заета`,
  `заети`,
  `заето`,
  `заеха`,
  `зайда`,
  `зайде`,
  `зайди`,
  `зайка`,
  `зайки`,
  `зайко`,
  `зайре`,
  `зайци`,
  `зайча`,
  `зайче`,
  `зайчи`,
  `закла`,
  `закле`,
  `закон`,
  `закри`,
  `залая`,
  `залее`,
  `залеж`,
  `залез`,
  `залея`,
  `залив`,
  `залог`,
  `залоя`,
  `залпа`,
  `залче`,
  `залък`,
  `залял`,
  `залян`,
  `залят`,
  `заман`,
  `замах`,
  `замая`,
  `замба`,
  `замби`,
  `замел`,
  `замит`,
  `замия`,
  `замля`,
  `замра`,
  `замре`,
  `замри`,
  `замря`,
  `замък`,
  `занев`,
  `заник`,
  `занят`,
  `заора`,
  `заоре`,
  `запад`,
  `запас`,
  `запее`,
  `запей`,
  `запек`,
  `запея`,
  `запис`,
  `запия`,
  `заплю`,
  `запои`,
  `запой`,
  `запор`,
  `запоя`,
  `запра`,
  `запре`,
  `запри`,
  `запял`,
  `заран`,
  `зарар`,
  `зарев`,
  `зарея`,
  `зарие`,
  `зарил`,
  `зарин`,
  `зарит`,
  `зарих`,
  `зария`,
  `зарко`,
  `заров`,
  `зароя`,
  `зарче`,
  `зарът`,
  `заряд`,
  `засее`,
  `засей`,
  `засея`,
  `засия`,
  `засне`,
  `заспа`,
  `заспи`,
  `заспя`,
  `засял`,
  `засят`,
  `засях`,
  `затаи`,
  `затая`,
  `затри`,
  `затуй`,
  `зауча`,
  `заучи`,
  `захар`,
  `захов`,
  `заход`,
  `зачел`,
  `зачуя`,
  `зашев`,
  `зашие`,
  `зашил`,
  `зашит`,
  `зашия`,
  `зашла`,
  `зашли`,
  `зашло`,
  `зашъл`,
  `заяви`,
  `заявя`,
  `заяде`,
  `звена`,
  `звено`,
  `звери`,
  `звука`,
  `звуко`,
  `звуци`,
  `звуча`,
  `звучи`,
  `звъна`,
  `звъни`,
  `звъня`,
  `звяра`,
  `здрав`,
  `здрач`,
  `зебил`,
  `зебло`,
  `зебра`,
  `зебри`,
  `зеела`,
  `зеещи`,
  `зейва`,
  `зейка`,
  `зелев`,
  `зелен`,
  `зелия`,
  `зелка`,
  `земен`,
  `земна`,
  `земни`,
  `земно`,
  `земьо`,
  `зенит`,
  `зетко`,
  `зетят`,
  `зефир`,
  `зидам`,
  `зидан`,
  `зидар`,
  `зидат`,
  `зидът`,
  `зимен`,
  `зимна`,
  `зимни`,
  `зимно`,
  `зимъс`,
  `зинат`,
  `злата`,
  `злати`,
  `злато`,
  `златя`,
  `злина`,
  `злини`,
  `злите`,
  `злият`,
  `злоба`,
  `злост`,
  `злота`,
  `злоти`,
  `злото`,
  `злояд`,
  `змеят`,
  `змияр`,
  `знаел`,
  `знаем`,
  `знаен`,
  `знаех`,
  `знаеш`,
  `знаещ`,
  `знака`,
  `знаме`,
  `знаци`,
  `знача`,
  `значи`,
  `знаят`,
  `зноен`,
  `зноят`,
  `зобам`,
  `зобна`,
  `зобта`,
  `зоват`,
  `зовем`,
  `зовеш`,
  `зовът`,
  `зовял`,
  `зодии`,
  `зодия`,
  `зокум`,
  `зомби`,
  `зонов`,
  `зорка`,
  `зорко`,
  `зорък`,
  `зорът`,
  `зрака`,
  `зреещ`,
  `зрели`,
  `зреят`,
  `зряла`,
  `зряло`,
  `зрящи`,
  `зубри`,
  `зубря`,
  `зубър`,
  `зуека`,
  `зулум`,
  `зумер`,
  `зунка`,
  `зурла`,
  `зурли`,
  `зурна`,
  `зурни`,
  `зъбар`,
  `зъбат`,
  `зъбен`,
  `зъбер`,
  `зъбец`,
  `зъбки`,
  `зъбна`,
  `зъбни`,
  `зъбно`,
  `зъбци`,
  `зъбче`,
  `зъбът`,
  `зъбят`,
  `зъзна`,
  `зъзне`,
  `зълва`,
  `зълви`,
  `зърва`,
  `зърна`,
  `зърне`,
  `зърно`,
  `зяпам`,
  `зяпат`,
  `зяпач`,
  `зяпаш`,
  `зяпли`,
  `ибекс`,
  `ибери`,
  `ибиса`,
  `ибиси`,
  `ибрик`,
  `ивана`,
  `ивеко`,
  `ивета`,
  `ивица`,
  `ивици`,
  `ивков`,
  `ивоар`,
  `ивова`,
  `ивона`,
  `иврит`,
  `ивчев`,
  `иглен`,
  `игнат`,
  `игото`,
  `играе`,
  `играй`,
  `играл`,
  `игран`,
  `играх`,
  `играч`,
  `играя`,
  `игрек`,
  `игрив`,
  `идвай`,
  `идвал`,
  `идвам`,
  `идват`,
  `идваш`,
  `идващ`,
  `идеал`,
  `идеен`,
  `идела`,
  `идели`,
  `идело`,
  `идете`,
  `идеха`,
  `идеше`,
  `идеща`,
  `идещи`,
  `идещо`,
  `идиом`,
  `идиот`,
  `идния`,
  `идола`,
  `идоли`,
  `избен`,
  `избие`,
  `избий`,
  `избил`,
  `избит`,
  `избия`,
  `избол`,
  `избор`,
  `избра`,
  `избул`,
  `избуя`,
  `извае`,
  `извая`,
  `извел`,
  `извея`,
  `извие`,
  `извит`,
  `извия`,
  `извод`,
  `извоз`,
  `извор`,
  `изври`,
  `извря`,
  `извън`,
  `изгни`,
  `изгря`,
  `издай`,
  `издал`,
  `издам`,
  `издои`,
  `издоя`,
  `издул`,
  `издут`,
  `издуя`,
  `изеча`,
  `иззад`,
  `иззел`,
  `иззет`,
  `иззех`,
  `изказ`,
  `изкла`,
  `изкоп`,
  `излаз`,
  `излак`,
  `излая`,
  `излее`,
  `излез`,
  `излей`,
  `излет`,
  `излея`,
  `излял`,
  `излян`,
  `излят`,
  `излях`,
  `измет`,
  `измие`,
  `измий`,
  `измил`,
  `измир`,
  `измит`,
  `измих`,
  `измия`,
  `измля`,
  `измра`,
  `измре`,
  `измри`,
  `измря`,
  `износ`,
  `изора`,
  `изоре`,
  `изори`,
  `изпее`,
  `изпей`,
  `изпея`,
  `изпие`,
  `изпий`,
  `изпил`,
  `изпит`,
  `изпих`,
  `изпия`,
  `изплю`,
  `изпод`,
  `изпра`,
  `изпял`,
  `изпян`,
  `изпят`,
  `изпях`,
  `израз`,
  `изрез`,
  `изрив`,
  `изрил`,
  `изрия`,
  `изрод`,
  `изроя`,
  `изсмя`,
  `изток`,
  `изтри`,
  `изува`,
  `изуми`,
  `изумя`,
  `изуча`,
  `изучи`,
  `изход`,
  `изчел`,
  `изшия`,
  `изява`,
  `изяви`,
  `изявя`,
  `изяде`,
  `изяла`,
  `изяли`,
  `изяло`,
  `икона`,
  `икони`,
  `иктер`,
  `илачи`,
  `илеит`,
  `илеум`,
  `илеус`,
  `илиев`,
  `илика`,
  `илири`,
  `илици`,
  `илиян`,
  `илков`,
  `илона`,
  `илчев`,
  `имаго`,
  `имала`,
  `имали`,
  `имало`,
  `имама`,
  `имаме`,
  `имами`,
  `имана`,
  `имане`,
  `имано`,
  `имате`,
  `имаха`,
  `имаше`,
  `имаща`,
  `имащи`,
  `имащо`,
  `имейл`,
  `имела`,
  `имена`,
  `името`,
  `имидж`,
  `имота`,
  `имоти`,
  `импас`,
  `ината`,
  `инати`,
  `иначе`,
  `индже`,
  `индий`,
  `индия`,
  `индус`,
  `иново`,
  `иноци`,
  `интел`,
  `интра`,
  `интро`,
  `инчов`,
  `инчът`,
  `иприт`,
  `ирена`,
  `ирина`,
  `ириса`,
  `ириси`,
  `ирник`,
  `исаев`,
  `искай`,
  `искал`,
  `искам`,
  `искан`,
  `искат`,
  `исках`,
  `искаш`,
  `искащ`,
  `исков`,
  `искра`,
  `искри`,
  `искря`,
  `искър`,
  `искът`,
  `ислям`,
  `итака`,
  `итрий`,
  `итрия`,
  `ицова`,
  `ичера`,
  `ичкия`,
  `ишиас`,
  `ищеца`,
  `йемен`,
  `йерей`,
  `йоана`,
  `йовка`,
  `йовко`,
  `йовов`,
  `йовчо`,
  `йоден`,
  `йодид`,
  `йодна`,
  `йодни`,
  `йодно`,
  `йодов`,
  `йодът`,
  `йозов`,
  `йолов`,
  `йонев`,
  `йонен`,
  `йонка`,
  `йонко`,
  `йонна`,
  `йонни`,
  `йонно`,
  `йонът`,
  `йосиф`,
  `йотов`,
  `йоцов`,
  `кабар`,
  `кабда`,
  `кабел`,
  `кабул`,
  `кабър`,
  `кавак`,
  `кавал`,
  `кавга`,
  `кавги`,
  `кавър`,
  `кадем`,
  `каден`,
  `кадет`,
  `кадии`,
  `кадил`,
  `кадир`,
  `кадиш`,
  `кадия`,
  `кадри`,
  `кадро`,
  `кадър`,
  `кадят`,
  `каене`,
  `каете`,
  `каещи`,
  `кажат`,
  `кажел`,
  `кажем`,
  `кажех`,
  `кажеш`,
  `казак`,
  `казал`,
  `казан`,
  `казах`,
  `казва`,
  `казус`,
  `казън`,
  `каика`,
  `каици`,
  `каиша`,
  `каиши`,
  `кайма`,
  `кайро`,
  `кайта`,
  `какао`,
  `каква`,
  `какви`,
  `какво`,
  `какин`,
  `както`,
  `какъв`,
  `калай`,
  `калая`,
  `калев`,
  `калем`,
  `кален`,
  `калий`,
  `калил`,
  `калим`,
  `калин`,
  `калиш`,
  `калия`,
  `калка`,
  `кална`,
  `кални`,
  `кално`,
  `калта`,
  `калфа`,
  `калфи`,
  `калци`,
  `калчо`,
  `калъп`,
  `калъф`,
  `калъч`,
  `калял`,
  `калям`,
  `калян`,
  `калят`,
  `камаз`,
  `камба`,
  `камби`,
  `камен`,
  `камея`,
  `камов`,
  `камък`,
  `камъш`,
  `канал`,
  `канап`,
  `канел`,
  `канен`,
  `канех`,
  `кании`,
  `канил`,
  `каним`,
  `каниц`,
  `каниш`,
  `кания`,
  `канон`,
  `канта`,
  `канче`,
  `каняк`,
  `канят`,
  `капак`,
  `капан`,
  `капва`,
  `капел`,
  `капеш`,
  `капии`,
  `капия`,
  `капка`,
  `капки`,
  `капла`,
  `капли`,
  `капна`,
  `капне`,
  `капра`,
  `капри`,
  `капса`,
  `капси`,
  `капят`,
  `карай`,
  `карал`,
  `карам`,
  `каран`,
  `карат`,
  `карах`,
  `карач`,
  `караш`,
  `каращ`,
  `карга`,
  `карго`,
  `карло`,
  `карма`,
  `карми`,
  `карст`,
  `карта`,
  `карти`,
  `карък`,
  `касае`,
  `касай`,
  `касая`,
  `касис`,
  `каска`,
  `каски`,
  `каско`,
  `касов`,
  `каста`,
  `касти`,
  `катар`,
  `катер`,
  `катет`,
  `катил`,
  `катма`,
  `катми`,
  `катод`,
  `катун`,
  `катък`,
  `катър`,
  `кауза`,
  `каузи`,
  `кауша`,
  `кауши`,
  `кафез`,
  `кафен`,
  `кафяв`,
  `кахел`,
  `кахър`,
  `кацал`,
  `кацам`,
  `кацар`,
  `кацат`,
  `кацащ`,
  `кацва`,
  `кацна`,
  `кацне`,
  `качат`,
  `качва`,
  `качен`,
  `качил`,
  `качим`,
  `качих`,
  `качиш`,
  `качов`,
  `качул`,
  `кашав`,
  `кашер`,
  `кашик`,
  `кашля`,
  `кашон`,
  `кашпа`,
  `каюта`,
  `каюти`,
  `каяка`,
  `каяли`,
  `каяци`,
  `квака`,
  `квант`,
  `кварк`,
  `кварц`,
  `кваса`,
  `квася`,
  `квича`,
  `квота`,
  `квоти`,
  `кебап`,
  `кегел`,
  `кегла`,
  `кегли`,
  `кедри`,
  `кедър`,
  `кейка`,
  `кекав`,
  `келеш`,
  `келти`,
  `келяв`,
  `кемал`,
  `кенар`,
  `кендо`,
  `кенеф`,
  `кения`,
  `кепче`,
  `кесар`,
  `кесии`,
  `кесим`,
  `кесия`,
  `кесон`,
  `кетон`,
  `кефал`,
  `кефир`,
  `кефиш`,
  `кефна`,
  `кефне`,
  `кефят`,
  `кехаи`,
  `кехая`,
  `кечът`,
  `кешки`,
  `кешът`,
  `кибер`,
  `кибик`,
  `кибуц`,
  `кивот`,
  `кикот`,
  `килва`,
  `килер`,
  `килии`,
  `килим`,
  `килия`,
  `килна`,
  `килне`,
  `килър`,
  `килът`,
  `кимам`,
  `кимат`,
  `кимащ`,
  `кимва`,
  `кимна`,
  `кинта`,
  `киоск`,
  `кипва`,
  `кипеж`,
  `кипим`,
  `кипна`,
  `кипне`,
  `кипра`,
  `кипри`,
  `кипря`,
  `кипър`,
  `кипял`,
  `кипят`,
  `кипящ`,
  `кирев`,
  `киреч`,
  `кирил`,
  `кирия`,
  `кирка`,
  `кирки`,
  `киров`,
  `кирта`,
  `кирчо`,
  `кисел`,
  `киска`,
  `кисна`,
  `кисне`,
  `киста`,
  `кисти`,
  `китай`,
  `китап`,
  `кител`,
  `китен`,
  `китил`,
  `китим`,
  `китка`,
  `китки`,
  `китна`,
  `китни`,
  `китно`,
  `китов`,
  `китът`,
  `кифла`,
  `кифли`,
  `кихам`,
  `кихат`,
  `кихна`,
  `кихне`,
  `кихот`,
  `кичат`,
  `кичим`,
  `кичур`,
  `кичът`,
  `кишав`,
  `клада`,
  `кладе`,
  `клади`,
  `клали`,
  `клало`,
  `клана`,
  `клане`,
  `клани`,
  `клано`,
  `кланя`,
  `клапа`,
  `клапи`,
  `клара`,
  `класа`,
  `класи`,
  `клася`,
  `клати`,
  `клатя`,
  `клаха`,
  `клека`,
  `клела`,
  `клели`,
  `клело`,
  `клема`,
  `клеми`,
  `клена`,
  `клепа`,
  `клепе`,
  `клепи`,
  `клепя`,
  `клета`,
  `клети`,
  `клето`,
  `клеха`,
  `клеча`,
  `клечи`,
  `клещи`,
  `клеят`,
  `клика`,
  `клики`,
  `клима`,
  `клина`,
  `клинч`,
  `клипа`,
  `клипс`,
  `клира`,
  `клише`,
  `клона`,
  `клони`,
  `клоня`,
  `клоун`,
  `клуба`,
  `клупа`,
  `кльон`,
  `клюка`,
  `клюки`,
  `клюна`,
  `ключа`,
  `ключе`,
  `кляка`,
  `кмета`,
  `кмете`,
  `кнежа`,
  `кнехт`,
  `книга`,
  `книги`,
  `книжа`,
  `княже`,
  `княза`,
  `князе`,
  `коала`,
  `коали`,
  `коани`,
  `кобдо`,
  `кобол`,
  `кобра`,
  `кобри`,
  `кобур`,
  `ковал`,
  `кован`,
  `коват`,
  `ковач`,
  `ковем`,
  `ковил`,
  `ковка`,
  `ковки`,
  `ковко`,
  `ковък`,
  `ковял`,
  `кодек`,
  `кодер`,
  `коджа`,
  `кодов`,
  `кодът`,
  `коева`,
  `което`,
  `кожар`,
  `кожен`,
  `кожна`,
  `кожни`,
  `кожно`,
  `кожух`,
  `козар`,
  `козел`,
  `козен`,
  `козил`,
  `козин`,
  `козия`,
  `козле`,
  `козли`,
  `козма`,
  `козни`,
  `козов`,
  `козът`,
  `козяк`,
  `които`,
  `койка`,
  `койки`,
  `койот`,
  `който`,
  `кокал`,
  `кокер`,
  `кокни`,
  `кокос`,
  `кокса`,
  `колаж`,
  `колай`,
  `колак`,
  `колан`,
  `колар`,
  `колач`,
  `колба`,
  `колби`,
  `колев`,
  `колеж`,
  `колел`,
  `колен`,
  `колет`,
  `колех`,
  `колец`,
  `колещ`,
  `колие`,
  `колим`,
  `колит`,
  `колиш`,
  `колко`,
  `колос`,
  `колта`,
  `колци`,
  `колче`,
  `колчо`,
  `колът`,
  `кольо`,
  `колят`,
  `комай`,
  `комар`,
  `комат`,
  `комби`,
  `комик`,
  `комин`,
  `комка`,
  `комки`,
  `конак`,
  `конго`,
  `конен`,
  `конец`,
  `конна`,
  `конни`,
  `конно`,
  `конов`,
  `коноп`,
  `конте`,
  `конти`,
  `конто`,
  `контя`,
  `конус`,
  `конуш`,
  `конха`,
  `конци`,
  `конче`,
  `коняк`,
  `коняр`,
  `конят`,
  `копае`,
  `копай`,
  `копал`,
  `копан`,
  `копач`,
  `копая`,
  `копва`,
  `копие`,
  `копия`,
  `копка`,
  `копки`,
  `копна`,
  `копои`,
  `копой`,
  `копче`,
  `копър`,
  `кораб`,
  `корав`,
  `корал`,
  `коран`,
  `корда`,
  `корди`,
  `корем`,
  `корен`,
  `корея`,
  `кории`,
  `кориш`,
  `кория`,
  `корка`,
  `корми`,
  `кормя`,
  `корса`,
  `корси`,
  `корта`,
  `корфу`,
  `косар`,
  `косач`,
  `косев`,
  `косер`,
  `косил`,
  `косих`,
  `косиш`,
  `косми`,
  `коста`,
  `кости`,
  `косъм`,
  `косът`,
  `косьо`,
  `косят`,
  `котак`,
  `котва`,
  `котви`,
  `котел`,
  `котен`,
  `котиш`,
  `котка`,
  `котки`,
  `котле`,
  `котли`,
  `кофар`,
  `кофти`,
  `коцев`,
  `коцов`,
  `кочан`,
  `кочев`,
  `кочия`,
  `кошер`,
  `кошии`,
  `кошия`,
  `кошов`,
  `кошче`,
  `кошът`,
  `която`,
  `краба`,
  `крава`,
  `краве`,
  `крави`,
  `краво`,
  `крада`,
  `краде`,
  `кради`,
  `краев`,
  `краен`,
  `крака`,
  `крала`,
  `крале`,
  `крали`,
  `крало`,
  `кралю`,
  `краля`,
  `крана`,
  `краси`,
  `крася`,
  `краха`,
  `крача`,
  `краче`,
  `крачи`,
  `краят`,
  `креда`,
  `креди`,
  `кредо`,
  `крееш`,
  `крека`,
  `крема`,
  `крена`,
  `креол`,
  `крепи`,
  `крепя`,
  `крета`,
  `крещи`,
  `крещя`,
  `крива`,
  `криви`,
  `криво`,
  `кривя`,
  `криел`,
  `крием`,
  `криеш`,
  `криещ`,
  `криза`,
  `кризи`,
  `крика`,
  `крила`,
  `криле`,
  `крили`,
  `крило`,
  `крими`,
  `крина`,
  `крини`,
  `криси`,
  `крита`,
  `крити`,
  `крито`,
  `крият`,
  `кроеж`,
  `кроен`,
  `кроим`,
  `крона`,
  `крони`,
  `кроса`,
  `кроул`,
  `кроше`,
  `кроял`,
  `кроят`,
  `крояч`,
  `круиз`,
  `крути`,
  `круша`,
  `круши`,
  `кръга`,
  `кръжа`,
  `кръжи`,
  `кръст`,
  `куала`,
  `кубик`,
  `кубче`,
  `кубът`,
  `кузев`,
  `кукай`,
  `кукал`,
  `кукам`,
  `кукер`,
  `кукла`,
  `кукли`,
  `кукло`,
  `кукля`,
  `кукне`,
  `куков`,
  `кулак`,
  `култа`,
  `кумец`,
  `кумир`,
  `кумис`,
  `кумов`,
  `кумът`,
  `кунев`,
  `кунка`,
  `кунчо`,
  `куньо`,
  `купел`,
  `купен`,
  `купил`,
  `купим`,
  `купих`,
  `купиш`,
  `купол`,
  `купом`,
  `купон`,
  `купче`,
  `купър`,
  `купът`,
  `купят`,
  `кураж`,
  `курва`,
  `курви`,
  `курво`,
  `курии`,
  `курия`,
  `курка`,
  `курса`,
  `курск`,
  `кусай`,
  `кусам`,
  `кусан`,
  `кусат`,
  `кусва`,
  `кусна`,
  `кусур`,
  `кутев`,
  `кутии`,
  `кутия`,
  `кутре`,
  `кутюр`,
  `куфар`,
  `кухия`,
  `кухни`,
  `кухня`,
  `куцал`,
  `куцам`,
  `куцат`,
  `куцащ`,
  `куция`,
  `кучев`,
  `кучен`,
  `кучия`,
  `кучка`,
  `кучки`,
  `кучко`,
  `кушии`,
  `кушия`,
  `кушла`,
  `кхмер`,
  `кхоза`,
  `къдри`,
  `къдря`,
  `къкря`,
  `кълба`,
  `кълби`,
  `кълбо`,
  `кълбя`,
  `кълва`,
  `кълве`,
  `кълка`,
  `кълки`,
  `кълна`,
  `кълне`,
  `кълня`,
  `кълца`,
  `кълча`,
  `кълчи`,
  `къмто`,
  `кънев`,
  `кънка`,
  `кънки`,
  `кънов`,
  `кънти`,
  `кънтя`,
  `кънчо`,
  `къпал`,
  `къпан`,
  `къпел`,
  `къпят`,
  `кърви`,
  `кървя`,
  `кърка`,
  `кърма`,
  `кърми`,
  `кърмя`,
  `кърня`,
  `кърпа`,
  `кърпи`,
  `кърпя`,
  `кърти`,
  `къртя`,
  `кърша`,
  `кърши`,
  `късай`,
  `късак`,
  `късал`,
  `късам`,
  `късат`,
  `късах`,
  `късен`,
  `късия`,
  `късна`,
  `късни`,
  `късно`,
  `късче`,
  `кътай`,
  `кътал`,
  `кътам`,
  `кътат`,
  `къташ`,
  `кътев`,
  `кътен`,
  `кътни`,
  `кътче`,
  `къшеи`,
  `къшей`,
  `къшин`,
  `къшла`,
  `къшле`,
  `къшли`,
  `къщен`,
  `къщна`,
  `къщни`,
  `кьолн`,
  `кьосе`,
  `кьоше`,
  `кьошк`,
  `кюлче`,
  `кюмбе`,
  `кюмюр`,
  `кюнец`,
  `кюнци`,
  `кюпче`,
  `кюрда`,
  `кюрди`,
  `кюрий`,
  `кюрия`,
  `кюспе`,
  `кютук`,
  `кютюк`,
  `кюфте`,
  `кючек`,
  `кярът`,
  `лавка`,
  `лавки`,
  `лавра`,
  `лаври`,
  `лавър`,
  `лагер`,
  `лагос`,
  `ладии`,
  `ладия`,
  `лаеве`,
  `лаели`,
  `лаело`,
  `лаене`,
  `лаеща`,
  `лаещи`,
  `лазар`,
  `лазер`,
  `лазещ`,
  `лазил`,
  `лазиш`,
  `лазов`,
  `лазур`,
  `лазят`,
  `лаика`,
  `лаици`,
  `лайка`,
  `лайки`,
  `лайна`,
  `лайно`,
  `лайта`,
  `лайфа`,
  `лакеи`,
  `лакей`,
  `лаков`,
  `лаком`,
  `лакта`,
  `лакти`,
  `лакът`,
  `лалев`,
  `лалка`,
  `лалов`,
  `лампа`,
  `лампи`,
  `ламти`,
  `ламтя`,
  `ланец`,
  `ланка`,
  `ланци`,
  `ланча`,
  `ланчи`,
  `лапад`,
  `лапам`,
  `лапат`,
  `лапах`,
  `лапка`,
  `лапки`,
  `лапна`,
  `лапни`,
  `ларва`,
  `ларви`,
  `ларго`,
  `ласка`,
  `ласки`,
  `лафел`,
  `лафет`,
  `лафче`,
  `лачев`,
  `лачен`,
  `лашва`,
  `лашка`,
  `лебед`,
  `левак`,
  `левга`,
  `левги`,
  `левия`,
  `левка`,
  `левов`,
  `левци`,
  `левче`,
  `левъл`,
  `левът`,
  `легат`,
  `леген`,
  `легии`,
  `легия`,
  `легла`,
  `легло`,
  `легна`,
  `легне`,
  `легни`,
  `леден`,
  `ледът`,
  `леела`,
  `леели`,
  `леене`,
  `лееща`,
  `лежал`,
  `лежат`,
  `лежах`,
  `лежащ`,
  `лежим`,
  `лежиш`,
  `лезия`,
  `леите`,
  `лейди`,
  `лейка`,
  `лейки`,
  `лейте`,
  `лекар`,
  `лекия`,
  `лекът`,
  `лелее`,
  `лелей`,
  `лелея`,
  `лелин`,
  `лелия`,
  `лелка`,
  `лелки`,
  `лельо`,
  `лемеж`,
  `лемур`,
  `ленен`,
  `ленив`,
  `ленин`,
  `лених`,
  `ленно`,
  `лента`,
  `ленти`,
  `ленът`,
  `леоне`,
  `лепва`,
  `лепен`,
  `лепим`,
  `лепиш`,
  `лепка`,
  `лепки`,
  `лепна`,
  `лепне`,
  `лепра`,
  `лепта`,
  `лепят`,
  `лесен`,
  `леска`,
  `лески`,
  `лесна`,
  `лесни`,
  `лесно`,
  `летва`,
  `летви`,
  `летеж`,
  `летен`,
  `летец`,
  `летим`,
  `летиш`,
  `летни`,
  `летци`,
  `летял`,
  `летят`,
  `летях`,
  `летящ`,
  `лефер`,
  `лечев`,
  `лечим`,
  `лешко`,
  `лещак`,
  `лещен`,
  `леяри`,
  `леята`,
  `лиана`,
  `лиани`,
  `либия`,
  `либра`,
  `либри`,
  `ливан`,
  `ливна`,
  `ливни`,
  `лигав`,
  `лигла`,
  `лидер`,
  `лидия`,
  `лижат`,
  `лижем`,
  `лизан`,
  `лизин`,
  `лизна`,
  `лизне`,
  `лизол`,
  `ликов`,
  `ликра`,
  `ликът`,
  `лилав`,
  `лилии`,
  `лилия`,
  `лилка`,
  `лилко`,
  `лилов`,
  `лиляк`,
  `лилян`,
  `лиляч`,
  `лиман`,
  `лимба`,
  `лимец`,
  `лимит`,
  `лимон`,
  `лимфа`,
  `линее`,
  `линей`,
  `линея`,
  `линии`,
  `линия`,
  `линка`,
  `линус`,
  `линча`,
  `линът`,
  `лионе`,
  `липак`,
  `липен`,
  `липец`,
  `липид`,
  `липов`,
  `липом`,
  `липса`,
  `липси`,
  `лирик`,
  `лисва`,
  `лисец`,
  `лисия`,
  `лиска`,
  `лисна`,
  `листа`,
  `листи`,
  `листо`,
  `литак`,
  `литва`,
  `литий`,
  `лития`,
  `литна`,
  `литне`,
  `литра`,
  `литри`,
  `литър`,
  `лифта`,
  `лихва`,
  `лихви`,
  `лицев`,
  `лицеи`,
  `лицей`,
  `лицея`,
  `личал`,
  `личат`,
  `личащ`,
  `личба`,
  `личби`,
  `личев`,
  `личен`,
  `личиш`,
  `лична`,
  `лични`,
  `лично`,
  `лишат`,
  `лишеи`,
  `лишей`,
  `лишен`,
  `лишея`,
  `лишил`,
  `лишим`,
  `лоара`,
  `лобен`,
  `лобна`,
  `лобни`,
  `лобно`,
  `лобош`,
  `лобут`,
  `ловен`,
  `ловец`,
  `ловеч`,
  `ловил`,
  `ловим`,
  `ловиш`,
  `ловка`,
  `ловки`,
  `ловко`,
  `ловна`,
  `ловни`,
  `ловно`,
  `ловци`,
  `ловча`,
  `ловък`,
  `ловът`,
  `ловят`,
  `ловящ`,
  `логва`,
  `логик`,
  `логия`,
  `логна`,
  `логне`,
  `логос`,
  `логът`,
  `лодка`,
  `лодки`,
  `лоени`,
  `лозан`,
  `лозар`,
  `лозев`,
  `лозен`,
  `лозно`,
  `лозов`,
  `лойта`,
  `локал`,
  `локам`,
  `локва`,
  `локви`,
  `локов`,
  `локум`,
  `лолов`,
  `ломен`,
  `ломец`,
  `ломци`,
  `ломът`,
  `лопуш`,
  `лопян`,
  `лорда`,
  `лорде`,
  `лоста`,
  `лосът`,
  `лотос`,
  `лотът`,
  `лочат`,
  `лошав`,
  `лошия`,
  `лувър`,
  `лугав`,
  `лудва`,
  `лудея`,
  `лудия`,
  `лудна`,
  `лукав`,
  `луков`,
  `лукса`,
  `лукът`,
  `лумба`,
  `лумва`,
  `лумен`,
  `лумна`,
  `лунав`,
  `лунен`,
  `лунна`,
  `лунни`,
  `лунно`,
  `лупам`,
  `лупна`,
  `лусия`,
  `лутат`,
  `луташ`,
  `лутащ`,
  `луфта`,
  `лучев`,
  `лучен`,
  `лучец`,
  `лъвов`,
  `лъвче`,
  `лъвът`,
  `лъгал`,
  `лъган`,
  `лъгах`,
  `лъжат`,
  `лъжел`,
  `лъжем`,
  `лъжех`,
  `лъжец`,
  `лъжеш`,
  `лъжещ`,
  `лъжла`,
  `лъжци`,
  `лъков`,
  `лъкът`,
  `лъсва`,
  `лъска`,
  `лъсна`,
  `лъсне`,
  `лъхам`,
  `лъхат`,
  `лъхва`,
  `лъхна`,
  `лъхтя`,
  `лъчев`,
  `лъчът`,
  `льоса`,
  `любаш`,
  `любен`,
  `любещ`,
  `любил`,
  `любим`,
  `любих`,
  `любиш`,
  `любка`,
  `любов`,
  `любча`,
  `любчо`,
  `любят`,
  `любящ`,
  `люков`,
  `люкът`,
  `люлее`,
  `люлея`,
  `люлин`,
  `люлка`,
  `люлки`,
  `люляк`,
  `люлян`,
  `люнет`,
  `люпен`,
  `люпят`,
  `люспа`,
  `люспи`,
  `лютив`,
  `лютим`,
  `лютих`,
  `лютия`,
  `лютни`,
  `лютня`,
  `лютят`,
  `люшка`,
  `люшна`,
  `люшне`,
  `лющят`,
  `лягай`,
  `лягал`,
  `лягам`,
  `лягат`,
  `ляков`,
  `лялев`,
  `лятна`,
  `лятно`,
  `лятос`,
  `маара`,
  `маари`,
  `маври`,
  `мавър`,
  `маган`,
  `магда`,
  `магер`,
  `магии`,
  `магия`,
  `магма`,
  `магми`,
  `магът`,
  `мадам`,
  `мадан`,
  `мажат`,
  `мажеш`,
  `мажор`,
  `мазал`,
  `мазан`,
  `мазач`,
  `мазва`,
  `мазда`,
  `мазев`,
  `мазен`,
  `мазка`,
  `мазки`,
  `мазна`,
  `мазни`,
  `мазно`,
  `мазол`,
  `мазут`,
  `маите`,
  `майка`,
  `майки`,
  `майко`,
  `майна`,
  `майно`,
  `майор`,
  `майте`,
  `майче`,
  `макак`,
  `макао`,
  `макар`,
  `макет`,
  `маков`,
  `макро`,
  `макси`,
  `макът`,
  `малак`,
  `малев`,
  `малин`,
  `малка`,
  `малки`,
  `малко`,
  `малта`,
  `малца`,
  `малък`,
  `мамба`,
  `мамби`,
  `мамбо`,
  `мамел`,
  `мамен`,
  `мамец`,
  `мамил`,
  `мамин`,
  `мамиш`,
  `мамка`,
  `мамул`,
  `мамут`,
  `мамят`,
  `манаф`,
  `манга`,
  `манго`,
  `манев`,
  `манеж`,
  `мании`,
  `мания`,
  `манна`,
  `манни`,
  `манов`,
  `манол`,
  `маноя`,
  `манта`,
  `манто`,
  `манту`,
  `манша`,
  `маньо`,
  `маора`,
  `маори`,
  `мараш`,
  `марги`,
  `марго`,
  `марда`,
  `марди`,
  `маржа`,
  `марин`,
  `марио`,
  `мария`,
  `марка`,
  `марки`,
  `марко`,
  `маркс`,
  `марли`,
  `марля`,
  `маров`,
  `марта`,
  `марша`,
  `марян`,
  `масаж`,
  `масив`,
  `маска`,
  `маски`,
  `масла`,
  `масло`,
  `масов`,
  `масон`,
  `маста`,
  `масур`,
  `матев`,
  `матей`,
  `матка`,
  `матки`,
  `матов`,
  `матът`,
  `мафии`,
  `мафия`,
  `махай`,
  `махал`,
  `махам`,
  `махан`,
  `махат`,
  `махах`,
  `махаш`,
  `махащ`,
  `махва`,
  `махди`,
  `махия`,
  `махла`,
  `махна`,
  `махне`,
  `махни`,
  `махов`,
  `мацам`,
  `мацев`,
  `мацка`,
  `мацки`,
  `мацна`,
  `мачев`,
  `мачка`,
  `мачта`,
  `мачти`,
  `мачът`,
  `машев`,
  `мащаб`,
  `маяка`,
  `маями`,
  `маята`,
  `маяци`,
  `мебел`,
  `меган`,
  `медал`,
  `медар`,
  `меден`,
  `медец`,
  `медии`,
  `медик`,
  `медия`,
  `медна`,
  `медни`,
  `медно`,
  `медов`,
  `медта`,
  `медът`,
  `межда`,
  `межди`,
  `между`,
  `мезек`,
  `мезиш`,
  `мезов`,
  `мезон`,
  `мейла`,
  `мейли`,
  `мейот`,
  `мекия`,
  `мелба`,
  `мелби`,
  `мелез`,
  `мелел`,
  `мелех`,
  `мелещ`,
  `мелим`,
  `мелиш`,
  `мелия`,
  `мелна`,
  `мелят`,
  `менев`,
  `менен`,
  `менза`,
  `мензи`,
  `менил`,
  `мениш`,
  `менка`,
  `менко`,
  `менов`,
  `мента`,
  `менте`,
  `менти`,
  `менци`,
  `менче`,
  `менял`,
  `менят`,
  `менящ`,
  `мерак`,
  `мерач`,
  `мерел`,
  `мерен`,
  `мерил`,
  `мерим`,
  `мерих`,
  `мериш`,
  `мерки`,
  `мерла`,
  `мерло`,
  `мерна`,
  `мерне`,
  `мерни`,
  `мерно`,
  `мерси`,
  `мерят`,
  `месал`,
  `месар`,
  `месач`,
  `месел`,
  `месен`,
  `месец`,
  `месии`,
  `месим`,
  `месих`,
  `месиш`,
  `месия`,
  `месна`,
  `месни`,
  `месно`,
  `места`,
  `мести`,
  `место`,
  `местя`,
  `месце`,
  `месят`,
  `метал`,
  `метан`,
  `метат`,
  `метач`,
  `метев`,
  `метеж`,
  `метем`,
  `метен`,
  `метил`,
  `метис`,
  `метла`,
  `метли`,
  `метна`,
  `метне`,
  `метни`,
  `метод`,
  `метох`,
  `метра`,
  `метри`,
  `метро`,
  `метъл`,
  `метър`,
  `метял`,
  `мехур`,
  `мехче`,
  `мехът`,
  `мечев`,
  `мечия`,
  `мечка`,
  `мечки`,
  `мечок`,
  `мечта`,
  `мечти`,
  `мечът`,
  `мешал`,
  `мешам`,
  `мешан`,
  `мешат`,
  `мешин`,
  `мешка`,
  `мешки`,
  `мивка`,
  `мивки`,
  `мигал`,
  `мигам`,
  `мигар`,
  `мигат`,
  `мигач`,
  `мигащ`,
  `мигла`,
  `мигли`,
  `мигна`,
  `мигне`,
  `мигом`,
  `мигът`,
  `миден`,
  `миела`,
  `миели`,
  `миело`,
  `миене`,
  `миете`,
  `миеха`,
  `миеща`,
  `миещи`,
  `миещо`,
  `мижав`,
  `мизия`,
  `мийте`,
  `миков`,
  `микре`,
  `микро`,
  `микса`,
  `милан`,
  `милва`,
  `милев`,
  `милее`,
  `милей`,
  `милен`,
  `милея`,
  `милия`,
  `милка`,
  `милко`,
  `милна`,
  `милни`,
  `милно`,
  `милов`,
  `милуш`,
  `милчо`,
  `мимик`,
  `мимът`,
  `минал`,
  `минат`,
  `минах`,
  `миндя`,
  `минев`,
  `миней`,
  `минел`,
  `минем`,
  `минен`,
  `минет`,
  `минеш`,
  `миний`,
  `миния`,
  `минка`,
  `минко`,
  `минна`,
  `минни`,
  `минно`,
  `минов`,
  `минор`,
  `минск`,
  `минус`,
  `минчо`,
  `миньо`,
  `миоза`,
  `мираж`,
  `мирев`,
  `мирен`,
  `мирим`,
  `мирис`,
  `мирко`,
  `мирна`,
  `мирне`,
  `мирни`,
  `мирно`,
  `миров`,
  `мирон`,
  `мирта`,
  `мирти`,
  `мирът`,
  `мисии`,
  `мисир`,
  `мисис`,
  `мисия`,
  `миска`,
  `миски`,
  `мисли`,
  `мисля`,
  `мисъл`,
  `митев`,
  `мития`,
  `митка`,
  `митко`,
  `митна`,
  `митни`,
  `митов`,
  `митра`,
  `митът`,
  `михов`,
  `мицев`,
  `мицел`,
  `мицов`,
  `мичев`,
  `мишев`,
  `мишия`,
  `мишка`,
  `мишки`,
  `мишле`,
  `мишок`,
  `мишца`,
  `мишци`,
  `миячи`,
  `млада`,
  `млади`,
  `младо`,
  `млати`,
  `млатя`,
  `млека`,
  `млеко`,
  `млели`,
  `млени`,
  `мливо`,
  `мляко`,
  `мляла`,
  `мляло`,
  `мляна`,
  `мляно`,
  `мляха`,
  `мнима`,
  `мними`,
  `мнимо`,
  `много`,
  `множа`,
  `множи`,
  `моаре`,
  `могат`,
  `могла`,
  `могли`,
  `могло`,
  `могол`,
  `могъл`,
  `могъщ`,
  `модев`,
  `модел`,
  `модем`,
  `моден`,
  `модна`,
  `модни`,
  `модно`,
  `модра`,
  `модри`,
  `модро`,
  `модул`,
  `модус`,
  `модър`,
  `моева`,
  `моему`,
  `моето`,
  `можах`,
  `можел`,
  `можем`,
  `можех`,
  `можеш`,
  `можещ`,
  `мозък`,
  `моите`,
  `мокев`,
  `мокет`,
  `мокра`,
  `мокри`,
  `мокро`,
  `мокря`,
  `мокси`,
  `мокша`,
  `мокър`,
  `молба`,
  `молби`,
  `молел`,
  `молен`,
  `молех`,
  `молец`,
  `молив`,
  `молил`,
  `молим`,
  `молих`,
  `молиш`,
  `молла`,
  `молли`,
  `молох`,
  `молци`,
  `молът`,
  `молят`,
  `момея`,
  `момин`,
  `момко`,
  `момци`,
  `момче`,
  `момък`,
  `монах`,
  `монев`,
  `монов`,
  `монте`,
  `моньо`,
  `морав`,
  `морал`,
  `морга`,
  `морда`,
  `морен`,
  `моржа`,
  `морил`,
  `морим`,
  `мориц`,
  `мориш`,
  `морна`,
  `морни`,
  `морно`,
  `морон`,
  `морук`,
  `морът`,
  `моряк`,
  `моста`,
  `мотае`,
  `мотай`,
  `мотал`,
  `мотам`,
  `мотат`,
  `мотая`,
  `мотел`,
  `мотет`,
  `мотив`,
  `мотка`,
  `мотор`,
  `мохер`,
  `мочур`,
  `мощен`,
  `мощна`,
  `мощни`,
  `мощно`,
  `мощта`,
  `моята`,
  `мрава`,
  `мрави`,
  `мраза`,
  `мрази`,
  `мразя`,
  `мрака`,
  `мрача`,
  `мрачи`,
  `мрежа`,
  `мрежи`,
  `мрели`,
  `мрени`,
  `мрете`,
  `мреше`,
  `мръщя`,
  `мряла`,
  `мряло`,
  `мряна`,
  `мряха`,
  `мугла`,
  `муден`,
  `мудна`,
  `мудни`,
  `мудно`,
  `мудра`,
  `мужик`,
  `музга`,
  `музеи`,
  `музей`,
  `музея`,
  `мулат`,
  `мулти`,
  `мумии`,
  `мумия`,
  `мунев`,
  `мунчо`,
  `мурга`,
  `мусел`,
  `мусил`,
  `муска`,
  `муски`,
  `мусон`,
  `мутра`,
  `мутри`,
  `муфел`,
  `муфта`,
  `муфти`,
  `мухал`,
  `мухам`,
  `мухан`,
  `мухла`,
  `мухъл`,
  `мушат`,
  `мушва`,
  `мушел`,
  `мушка`,
  `мушки`,
  `мушна`,
  `мушне`,
  `мушни`,
  `мъгла`,
  `мъгли`,
  `мъдра`,
  `мъдри`,
  `мъдро`,
  `мъдря`,
  `мъдър`,
  `мъждя`,
  `мъжец`,
  `мъжка`,
  `мъжки`,
  `мъжко`,
  `мъжле`,
  `мъжов`,
  `мъжът`,
  `мъзга`,
  `мъкна`,
  `мъкне`,
  `мъкни`,
  `мълва`,
  `мълви`,
  `мълвя`,
  `мълча`,
  `мълчи`,
  `мъмри`,
  `мъмря`,
  `мъник`,
  `мъпет`,
  `мърда`,
  `мързи`,
  `мърка`,
  `мърла`,
  `мърли`,
  `мърля`,
  `мърси`,
  `мърся`,
  `мърфи`,
  `мърша`,
  `мърши`,
  `мъсти`,
  `мъстя`,
  `мътен`,
  `мътиш`,
  `мътна`,
  `мътни`,
  `мътно`,
  `мътят`,
  `мъхав`,
  `мъхат`,
  `мъхов`,
  `мъхът`,
  `мъчат`,
  `мъчен`,
  `мъчех`,
  `мъчил`,
  `мъчим`,
  `мъчих`,
  `мъчиш`,
  `мъчна`,
  `мъчни`,
  `мъчно`,
  `мюрид`,
  `мюсли`,
  `мязам`,
  `мязат`,
  `мякам`,
  `мярка`,
  `мярна`,
  `място`,
  `мятай`,
  `мятал`,
  `мятам`,
  `мятат`,
  `мяука`,
  `мяуча`,
  `мяучи`,
  `набая`,
  `набег`,
  `набие`,
  `набий`,
  `набил`,
  `набит`,
  `набих`,
  `набия`,
  `набор`,
  `набра`,
  `навее`,
  `навей`,
  `навел`,
  `навес`,
  `навея`,
  `навзе`,
  `навие`,
  `навик`,
  `навил`,
  `навит`,
  `навих`,
  `навия`,
  `навло`,
  `навои`,
  `навой`,
  `навра`,
  `навря`,
  `навън`,
  `наган`,
  `нагар`,
  `нагла`,
  `нагли`,
  `нагло`,
  `нагон`,
  `нагоя`,
  `нагъл`,
  `надай`,
  `надал`,
  `надам`,
  `надве`,
  `надви`,
  `наден`,
  `надим`,
  `надир`,
  `надка`,
  `надои`,
  `надой`,
  `надоя`,
  `надпи`,
  `надуе`,
  `надут`,
  `надуя`,
  `надям`,
  `наежа`,
  `наела`,
  `наели`,
  `наело`,
  `наема`,
  `наеме`,
  `наеми`,
  `наета`,
  `наети`,
  `наето`,
  `наеха`,
  `назад`,
  `назал`,
  `найка`,
  `найчо`,
  `накев`,
  `накит`,
  `накла`,
  `наков`,
  `накуп`,
  `накъм`,
  `налая`,
  `налее`,
  `налей`,
  `налеп`,
  `налея`,
  `налог`,
  `налъм`,
  `налял`,
  `налят`,
  `налях`,
  `намаз`,
  `намек`,
  `намля`,
  `нанду`,
  `нанев`,
  `наниз`,
  `нанка`,
  `нанов`,
  `нанос`,
  `напад`,
  `напев`,
  `напет`,
  `напие`,
  `напил`,
  `напия`,
  `наплю`,
  `напои`,
  `напой`,
  `напор`,
  `напоя`,
  `напра`,
  `напук`,
  `напън`,
  `наред`,
  `нарез`,
  `нарил`,
  `нария`,
  `народ`,
  `нарът`,
  `наръч`,
  `наряд`,
  `насам`,
  `насау`,
  `насея`,
  `насип`,
  `наспа`,
  `наспи`,
  `насън`,
  `насят`,
  `натам`,
  `натан`,
  `натев`,
  `натов`,
  `натри`,
  `наука`,
  `науки`,
  `науми`,
  `наумя`,
  `науру`,
  `науча`,
  `научи`,
  `нафта`,
  `нафти`,
  `нахал`,
  `нахлу`,
  `нахут`,
  `нации`,
  `нация`,
  `начев`,
  `начин`,
  `начов`,
  `нашие`,
  `наший`,
  `нашир`,
  `нашит`,
  `наших`,
  `нашия`,
  `наяве`,
  `наяда`,
  `наяде`,
  `наяла`,
  `небен`,
  `небит`,
  `небце`,
  `невеж`,
  `невен`,
  `невещ`,
  `невис`,
  `невля`,
  `невша`,
  `невям`,
  `невян`,
  `негли`,
  `негов`,
  `негре`,
  `негри`,
  `негър`,
  `недан`,
  `недев`,
  `недей`,
  `неден`,
  `недка`,
  `недко`,
  `недра`,
  `недро`,
  `недъг`,
  `нежен`,
  `нежив`,
  `нежна`,
  `нежни`,
  `нежно`,
  `нейде`,
  `нейко`,
  `нейна`,
  `нейни`,
  `нейно`,
  `нейсе`,
  `неков`,
  `нелек`,
  `нелеп`,
  `нелош`,
  `немай`,
  `немее`,
  `немей`,
  `немец`,
  `немея`,
  `немил`,
  `немит`,
  `немия`,
  `немощ`,
  `немци`,
  `ненко`,
  `ненов`,
  `ненчо`,
  `неона`,
  `непал`,
  `нерад`,
  `нерва`,
  `нерви`,
  `нерез`,
  `несла`,
  `несъщ`,
  `нетев`,
  `нетен`,
  `нетна`,
  `нетни`,
  `нетно`,
  `неука`,
  `неуки`,
  `неуко`,
  `нефта`,
  `нехае`,
  `нехая`,
  `нецов`,
  `нечие`,
  `нечии`,
  `нечий`,
  `нечия`,
  `нечут`,
  `нешев`,
  `нешка`,
  `нивга`,
  `нигер`,
  `нижат`,
  `нижел`,
  `низан`,
  `низка`,
  `низки`,
  `низко`,
  `низов`,
  `низша`,
  `низше`,
  `низши`,
  `низък`,
  `низът`,
  `нийде`,
  `никак`,
  `никел`,
  `никна`,
  `никне`,
  `ников`,
  `никое`,
  `никои`,
  `никой`,
  `никоя`,
  `никът`,
  `никюп`,
  `нимба`,
  `нимфа`,
  `нимфи`,
  `нинов`,
  `нипел`,
  `нисан`,
  `ниска`,
  `ниски`,
  `ниско`,
  `нисша`,
  `нисше`,
  `нисши`,
  `нисък`,
  `нитов`,
  `ницше`,
  `ничев`,
  `ничие`,
  `ничии`,
  `ничий`,
  `ничия`,
  `нишан`,
  `нишка`,
  `нишки`,
  `нишко`,
  `нищят`,
  `нобел`,
  `новак`,
  `новия`,
  `нодул`,
  `ноеви`,
  `ножар`,
  `ножка`,
  `ножче`,
  `ножът`,
  `нокия`,
  `нокти`,
  `нокът`,
  `номад`,
  `номер`,
  `нонка`,
  `нончо`,
  `норка`,
  `норки`,
  `норма`,
  `норми`,
  `носач`,
  `носел`,
  `носен`,
  `носех`,
  `носещ`,
  `носии`,
  `носил`,
  `носим`,
  `носих`,
  `носиш`,
  `носия`,
  `носле`,
  `носна`,
  `носни`,
  `носно`,
  `носов`,
  `носът`,
  `носят`,
  `нотев`,
  `нотен`,
  `нотка`,
  `нотки`,
  `нотна`,
  `нотни`,
  `нотно`,
  `нощви`,
  `нощем`,
  `нощен`,
  `нощес`,
  `нощна`,
  `нощни`,
  `нощно`,
  `нощта`,
  `нрава`,
  `нрави`,
  `нравя`,
  `нужда`,
  `нужди`,
  `нужен`,
  `нужна`,
  `нужни`,
  `нужно`,
  `нулев`,
  `нумея`,
  `нучев`,
  `ньоки`,
  `нюанс`,
  `нютон`,
  `нюхът`,
  `нявга`,
  `някак`,
  `някое`,
  `някои`,
  `някой`,
  `някоя`,
  `нямай`,
  `нямал`,
  `нямам`,
  `нямат`,
  `нямах`,
  `нямаш`,
  `нямащ`,
  `нянка`,
  `оагня`,
  `оазис`,
  `оакал`,
  `обади`,
  `обадя`,
  `обаче`,
  `обаян`,
  `обвея`,
  `обвие`,
  `обвил`,
  `обвит`,
  `обвих`,
  `обвия`,
  `обгар`,
  `обеда`,
  `обеди`,
  `обект`,
  `обела`,
  `обели`,
  `обело`,
  `обеля`,
  `обема`,
  `обеме`,
  `обеми`,
  `обера`,
  `обере`,
  `обеси`,
  `обеся`,
  `обета`,
  `обети`,
  `обеха`,
  `обеца`,
  `обеци`,
  `обеща`,
  `обзел`,
  `обзет`,
  `обзех`,
  `обзор`,
  `обида`,
  `обиди`,
  `обидя`,
  `обира`,
  `обири`,
  `обиск`,
  `обица`,
  `обици`,
  `обича`,
  `обков`,
  `облак`,
  `облее`,
  `облея`,
  `облик`,
  `облия`,
  `облог`,
  `облян`,
  `облят`,
  `обмен`,
  `обоза`,
  `обози`,
  `обора`,
  `обори`,
  `оборя`,
  `обоят`,
  `образ`,
  `обрал`,
  `обран`,
  `обрат`,
  `обред`,
  `обрив`,
  `оброк`,
  `обръч`,
  `обсег`,
  `обува`,
  `обула`,
  `обута`,
  `обути`,
  `обуха`,
  `обуча`,
  `обучи`,
  `обуща`,
  `обуят`,
  `обход`,
  `обшит`,
  `обшия`,
  `общия`,
  `обява`,
  `обяви`,
  `обявя`,
  `обяда`,
  `обяди`,
  `овала`,
  `овали`,
  `оваля`,
  `овена`,
  `овеса`,
  `овощи`,
  `овраг`,
  `овчар`,
  `овчия`,
  `огазя`,
  `оглед`,
  `огнен`,
  `огнян`,
  `огняр`,
  `оголи`,
  `оголя`,
  `огрев`,
  `огрее`,
  `огрея`,
  `огрян`,
  `огрят`,
  `огъва`,
  `огъна`,
  `огъне`,
  `огъня`,
  `одата`,
  `одеве`,
  `одеон`,
  `одера`,
  `одере`,
  `одеса`,
  `оджак`,
  `одимя`,
  `одира`,
  `одита`,
  `одите`,
  `одити`,
  `одран`,
  `одрин`,
  `одуха`,
  `одъра`,
  `ожаля`,
  `ожена`,
  `ожени`,
  `оженя`,
  `оживи`,
  `оживя`,
  `ожуля`,
  `ожъна`,
  `ожъне`,
  `озари`,
  `озаря`,
  `озеки`,
  `озове`,
  `озона`,
  `озори`,
  `озоря`,
  `окадя`,
  `окажа`,
  `окаже`,
  `оказа`,
  `окаля`,
  `окапи`,
  `окапя`,
  `оката`,
  `окато`,
  `окача`,
  `окачи`,
  `окаян`,
  `океан`,
  `окиса`,
  `окиси`,
  `окича`,
  `окичи`,
  `окова`,
  `окове`,
  `окови`,
  `окозя`,
  `около`,
  `окопа`,
  `окопи`,
  `окорш`,
  `окоси`,
  `окося`,
  `окото`,
  `окотя`,
  `окръг`,
  `оксид`,
  `октан`,
  `октет`,
  `окуця`,
  `окуча`,
  `окъпе`,
  `окъпя`,
  `окъси`,
  `окъся`,
  `олеле`,
  `олени`,
  `олива`,
  `олижа`,
  `олимп`,
  `олово`,
  `олтар`,
  `олчев`,
  `олющя`,
  `оляна`,
  `омаен`,
  `омаеш`,
  `омажа`,
  `омаже`,
  `омара`,
  `омари`,
  `омаял`,
  `омаян`,
  `омбре`,
  `омега`,
  `омела`,
  `омеси`,
  `омеся`,
  `омета`,
  `омлет`,
  `омове`,
  `омъжа`,
  `омъжи`,
  `онази`,
  `онака`,
  `онези`,
  `оникс`,
  `онков`,
  `онова`,
  `оньор`,
  `опада`,
  `опази`,
  `опазя`,
  `опака`,
  `опаки`,
  `опако`,
  `опала`,
  `опаля`,
  `опаря`,
  `опаса`,
  `опаша`,
  `опаше`,
  `опаши`,
  `опвам`,
  `опека`,
  `опела`,
  `опели`,
  `опело`,
  `опера`,
  `опере`,
  `опери`,
  `оперя`,
  `опече`,
  `опиат`,
  `опива`,
  `опиев`,
  `опила`,
  `опило`,
  `опиля`,
  `опира`,
  `описа`,
  `описи`,
  `опита`,
  `опити`,
  `опиум`,
  `опича`,
  `опиша`,
  `опише`,
  `опиши`,
  `оплел`,
  `оплюе`,
  `оплют`,
  `оплюя`,
  `опора`,
  `опори`,
  `опрат`,
  `опрем`,
  `опреш`,
  `опрял`,
  `опрян`,
  `опрях`,
  `оптик`,
  `опуля`,
  `опуса`,
  `опуси`,
  `опуша`,
  `опции`,
  `опция`,
  `опъва`,
  `опъна`,
  `опъне`,
  `опява`,
  `опято`,
  `орала`,
  `орали`,
  `орало`,
  `оране`,
  `оранж`,
  `орани`,
  `орача`,
  `орачи`,
  `орачо`,
  `орган`,
  `оргии`,
  `оргия`,
  `орден`,
  `ордер`,
  `орева`,
  `орежа`,
  `ореже`,
  `орежи`,
  `орела`,
  `орели`,
  `ореол`,
  `орете`,
  `ореха`,
  `орехи`,
  `ореша`,
  `ореше`,
  `ориза`,
  `орион`,
  `ориса`,
  `оркът`,
  `орлин`,
  `орлов`,
  `орляк`,
  `орния`,
  `оромо`,
  `ороня`,
  `ороси`,
  `орося`,
  `орсов`,
  `орсоя`,
  `ортак`,
  `орфей`,
  `орхан`,
  `орхит`,
  `оряза`,
  `оряха`,
  `осака`,
  `осата`,
  `освен`,
  `осева`,
  `осеви`,
  `осево`,
  `осека`,
  `осела`,
  `осени`,
  `осеня`,
  `осера`,
  `осети`,
  `осече`,
  `осечи`,
  `осеял`,
  `осеян`,
  `осика`,
  `осила`,
  `осили`,
  `осина`,
  `осира`,
  `осите`,
  `осича`,
  `осияя`,
  `оскар`,
  `ослен`,
  `осмар`,
  `осмее`,
  `осмея`,
  `осмий`,
  `осмия`,
  `осмял`,
  `осмян`,
  `особа`,
  `особи`,
  `осова`,
  `осови`,
  `осово`,
  `осоли`,
  `осоля`,
  `осояд`,
  `осрал`,
  `осран`,
  `остен`,
  `остит`,
  `остра`,
  `остри`,
  `остро`,
  `остря`,
  `остър`,
  `осъди`,
  `осъдя`,
  `отава`,
  `отбив`,
  `отбие`,
  `отбил`,
  `отбир`,
  `отбит`,
  `отбия`,
  `отбой`,
  `отбол`,
  `отбор`,
  `отбра`,
  `отвее`,
  `отвей`,
  `отвел`,
  `отвес`,
  `ответ`,
  `отвея`,
  `отвил`,
  `отвия`,
  `отвод`,
  `отвор`,
  `отвъд`,
  `отвън`,
  `отвял`,
  `отгде`,
  `отдай`,
  `отдал`,
  `отдам`,
  `отдел`,
  `отдих`,
  `отека`,
  `отеля`,
  `отеца`,
  `отече`,
  `отзад`,
  `отзел`,
  `отзет`,
  `отзех`,
  `отзив`,
  `отива`,
  `отида`,
  `отиде`,
  `отиди`,
  `отича`,
  `отказ`,
  `откак`,
  `откат`,
  `откос`,
  `откри`,
  `откуп`,
  `откъм`,
  `откъс`,
  `отлее`,
  `отлея`,
  `отлив`,
  `отлят`,
  `отмие`,
  `отмил`,
  `отмит`,
  `отмия`,
  `отмра`,
  `отмре`,
  `отмри`,
  `отмря`,
  `отнел`,
  `отнет`,
  `отнех`,
  `отока`,
  `отопя`,
  `отоци`,
  `отпея`,
  `отпие`,
  `отпия`,
  `отпор`,
  `отпоя`,
  `отпра`,
  `отрая`,
  `отред`,
  `отрез`,
  `отрие`,
  `отрит`,
  `отрия`,
  `отрок`,
  `отряд`,
  `отсам`,
  `отсее`,
  `отсек`,
  `отсея`,
  `отспа`,
  `отспи`,
  `отспя`,
  `оттам`,
  `отток`,
  `оттук`,
  `отупа`,
  `отуча`,
  `отход`,
  `отчая`,
  `отчел`,
  `отчет`,
  `отшия`,
  `отщат`,
  `отщем`,
  `отщеш`,
  `отщял`,
  `отщях`,
  `отъни`,
  `отяде`,
  `офика`,
  `офиса`,
  `офиси`,
  `офити`,
  `офорт`,
  `офсет`,
  `офшор`,
  `охвам`,
  `охкам`,
  `охлюв`,
  `охота`,
  `охрен`,
  `охрид`,
  `охули`,
  `охуля`,
  `оцеля`,
  `оцени`,
  `оценя`,
  `оцета`,
  `очерк`,
  `очеша`,
  `очила`,
  `очите`,
  `очния`,
  `очуша`,
  `ошава`,
  `ошане`,
  `ошутя`,
  `ощава`,
  `ощавя`,
  `ощети`,
  `ощетя`,
  `ощипе`,
  `ощипя`,
  `паваж`,
  `павел`,
  `пагон`,
  `падай`,
  `падал`,
  `падам`,
  `падат`,
  `падах`,
  `падаш`,
  `падащ`,
  `падеж`,
  `падеш`,
  `падна`,
  `падне`,
  `падни`,
  `падок`,
  `падре`,
  `падуа`,
  `падът`,
  `паеве`,
  `паели`,
  `паеля`,
  `пажът`,
  `пазар`,
  `пазач`,
  `пазва`,
  `пазви`,
  `пазел`,
  `пазен`,
  `пазех`,
  `пазещ`,
  `пазил`,
  `пазим`,
  `пазиш`,
  `пазят`,
  `пайет`,
  `пакет`,
  `пакта`,
  `палав`,
  `палас`,
  `палат`,
  `палач`,
  `палаш`,
  `палеж`,
  `палел`,
  `пален`,
  `палео`,
  `палет`,
  `палех`,
  `палец`,
  `палил`,
  `палим`,
  `палиш`,
  `палка`,
  `палки`,
  `палма`,
  `палми`,
  `пална`,
  `пални`,
  `палта`,
  `палто`,
  `палци`,
  `палят`,
  `памет`,
  `памид`,
  `памир`,
  `памук`,
  `панда`,
  `панди`,
  `панев`,
  `панел`,
  `панер`,
  `панов`,
  `панта`,
  `панти`,
  `панчо`,
  `папаз`,
  `папам`,
  `папая`,
  `папие`,
  `папир`,
  `папка`,
  `папки`,
  `папуа`,
  `папур`,
  `парад`,
  `параф`,
  `парва`,
  `парел`,
  `парен`,
  `парех`,
  `париж`,
  `парии`,
  `парий`,
  `парил`,
  `париш`,
  `пария`,
  `парка`,
  `парки`,
  `парма`,
  `парна`,
  `парни`,
  `парно`,
  `паров`,
  `парса`,
  `парси`,
  `парти`,
  `паруш`,
  `парфе`,
  `парче`,
  `парят`,
  `пасаж`,
  `пасат`,
  `пасва`,
  `пасем`,
  `пасеш`,
  `пасив`,
  `пасла`,
  `пасли`,
  `пасло`,
  `пасне`,
  `паста`,
  `пасти`,
  `пасха`,
  `пасхи`,
  `пасът`,
  `пасял`,
  `пасящ`,
  `патев`,
  `пател`,
  `патил`,
  `патим`,
  `патих`,
  `патиш`,
  `патка`,
  `патки`,
  `патов`,
  `паток`,
  `патос`,
  `патча`,
  `пауза`,
  `паузи`,
  `паула`,
  `пауло`,
  `паулу`,
  `пауна`,
  `паунд`,
  `пауни`,
  `пафта`,
  `пафти`,
  `пачев`,
  `пачия`,
  `пачка`,
  `пачки`,
  `пашев`,
  `пашов`,
  `паяка`,
  `паяци`,
  `паяче`,
  `певец`,
  `певци`,
  `пегас`,
  `педал`,
  `пеева`,
  `пеела`,
  `пеели`,
  `пеело`,
  `пеене`,
  `пеете`,
  `пееха`,
  `пееше`,
  `пееща`,
  `пеещи`,
  `пеещо`,
  `пейка`,
  `пейки`,
  `пейна`,
  `пейте`,
  `пейчо`,
  `пекар`,
  `пекат`,
  `пекин`,
  `пекли`,
  `пекло`,
  `пекна`,
  `пеков`,
  `пекъл`,
  `пелев`,
  `пелин`,
  `пелов`,
  `пелте`,
  `пемза`,
  `пенев`,
  `пенел`,
  `пение`,
  `пенис`,
  `пениш`,
  `пения`,
  `пенка`,
  `пенко`,
  `пенов`,
  `пенса`,
  `пенчо`,
  `пеньо`,
  `пепев`,
  `пепел`,
  `пепит`,
  `пепси`,
  `перат`,
  `перач`,
  `перга`,
  `перде`,
  `перем`,
  `переш`,
  `перим`,
  `периш`,
  `перка`,
  `перки`,
  `перко`,
  `перла`,
  `перли`,
  `перна`,
  `перне`,
  `перни`,
  `перон`,
  `перса`,
  `перси`,
  `перца`,
  `перце`,
  `перът`,
  `песар`,
  `песен`,
  `песни`,
  `пести`,
  `песто`,
  `пестя`,
  `песът`,
  `петак`,
  `петев`,
  `петел`,
  `петит`,
  `петия`,
  `петка`,
  `петко`,
  `петле`,
  `петли`,
  `петна`,
  `петни`,
  `петно`,
  `петня`,
  `петра`,
  `петте`,
  `петък`,
  `петър`,
  `петьо`,
  `печал`,
  `печат`,
  `печем`,
  `печен`,
  `печка`,
  `печки`,
  `пешак`,
  `пешев`,
  `пешка`,
  `пешки`,
  `пещен`,
  `пещни`,
  `пещта`,
  `пиана`,
  `пиано`,
  `пиара`,
  `пиаца`,
  `пиаци`,
  `пивка`,
  `пивки`,
  `пивък`,
  `пиела`,
  `пиели`,
  `пиело`,
  `пиене`,
  `пиеса`,
  `пиеси`,
  `пиете`,
  `пиеха`,
  `пиеше`,
  `пиеща`,
  `пиещи`,
  `пиещо`,
  `пийва`,
  `пийна`,
  `пийне`,
  `пийни`,
  `пийте`,
  `пикае`,
  `пикап`,
  `пикая`,
  `пикел`,
  `пикла`,
  `пикни`,
  `пикня`,
  `пиков`,
  `пикоч`,
  `пикът`,
  `пилаф`,
  `пилев`,
  `пилее`,
  `пилей`,
  `пилен`,
  `пилея`,
  `пилиш`,
  `пилия`,
  `пилон`,
  `пилор`,
  `пилот`,
  `пилци`,
  `пилят`,
  `пиниз`,
  `пинии`,
  `пиния`,
  `пинка`,
  `пинки`,
  `пинта`,
  `пинти`,
  `пипав`,
  `пипай`,
  `пипал`,
  `пипам`,
  `пипан`,
  `пипат`,
  `пипах`,
  `пипаш`,
  `пипва`,
  `пипер`,
  `пипка`,
  `пипна`,
  `пипне`,
  `пипни`,
  `пират`,
  `пирга`,
  `пиреи`,
  `пирей`,
  `пирея`,
  `пирин`,
  `пирит`,
  `пирне`,
  `пиров`,
  `пирог`,
  `пирон`,
  `пирът`,
  `писал`,
  `писан`,
  `писар`,
  `писах`,
  `писач`,
  `писва`,
  `писев`,
  `писец`,
  `писии`,
  `писия`,
  `писка`,
  `писма`,
  `писмо`,
  `писна`,
  `писне`,
  `писни`,
  `писта`,
  `писти`,
  `писци`,
  `писък`,
  `питае`,
  `питай`,
  `питал`,
  `питам`,
  `питан`,
  `питат`,
  `питах`,
  `питаш`,
  `питащ`,
  `питая`,
  `питие`,
  `пития`,
  `питка`,
  `питки`,
  `питон`,
  `пичка`,
  `пички`,
  `пичът`,
  `пишат`,
  `пишел`,
  `пишем`,
  `пишех`,
  `пишеш`,
  `пишещ`,
  `пишка`,
  `пишки`,
  `пищен`,
  `пищиш`,
  `пищна`,
  `пищни`,
  `пищно`,
  `пищов`,
  `пищял`,
  `пищят`,
  `пищях`,
  `пияна`,
  `пияни`,
  `пияно`,
  `пиячи`,
  `пиячо`,
  `плава`,
  `плави`,
  `плавя`,
  `плажа`,
  `плаза`,
  `плака`,
  `плаки`,
  `плама`,
  `плана`,
  `пласт`,
  `плата`,
  `плати`,
  `плато`,
  `платя`,
  `плаун`,
  `плаха`,
  `плахи`,
  `плахо`,
  `плаца`,
  `плача`,
  `плаче`,
  `плачи`,
  `плаша`,
  `плаши`,
  `плаща`,
  `плебс`,
  `плеви`,
  `плевя`,
  `плези`,
  `плезя`,
  `плели`,
  `племе`,
  `плена`,
  `плени`,
  `пленя`,
  `плета`,
  `плете`,
  `плети`,
  `плещи`,
  `плещя`,
  `плеър`,
  `плика`,
  `плисе`,
  `плода`,
  `плодя`,
  `плонж`,
  `плота`,
  `плоха`,
  `плоча`,
  `плочи`,
  `площи`,
  `плува`,
  `плуга`,
  `плута`,
  `плуто`,
  `плъст`,
  `плъха`,
  `плюем`,
  `плюеш`,
  `плюла`,
  `плюли`,
  `плюна`,
  `плюса`,
  `плюта`,
  `плющи`,
  `плющя`,
  `плюят`,
  `плява`,
  `пляка`,
  `побая`,
  `побие`,
  `побий`,
  `побил`,
  `побит`,
  `побия`,
  `побои`,
  `побой`,
  `побоя`,
  `побък`,
  `повее`,
  `повеи`,
  `повей`,
  `повел`,
  `повет`,
  `повея`,
  `повиж`,
  `повик`,
  `повил`,
  `повит`,
  `повия`,
  `повод`,
  `повой`,
  `поври`,
  `повря`,
  `повят`,
  `погна`,
  `погни`,
  `погон`,
  `подай`,
  `подал`,
  `подам`,
  `подел`,
  `подем`,
  `подет`,
  `подех`,
  `подзе`,
  `подир`,
  `подла`,
  `подли`,
  `подло`,
  `подля`,
  `подов`,
  `подоя`,
  `подуе`,
  `подул`,
  `подут`,
  `подуя`,
  `подъл`,
  `подът`,
  `подям`,
  `поела`,
  `поели`,
  `поело`,
  `поема`,
  `поеме`,
  `поеми`,
  `поета`,
  `поете`,
  `поети`,
  `поето`,
  `поеха`,
  `поеше`,
  `пожар`,
  `позив`,
  `позна`,
  `позор`,
  `поили`,
  `поило`,
  `пойна`,
  `пойни`,
  `пойнт`,
  `покае`,
  `показ`,
  `покер`,
  `покои`,
  `покой`,
  `покоя`,
  `покри`,
  `полай`,
  `полая`,
  `полее`,
  `полез`,
  `полей`,
  `полен`,
  `полет`,
  `полея`,
  `полза`,
  `ползи`,
  `полип`,
  `полис`,
  `полит`,
  `полка`,
  `полки`,
  `полов`,
  `полог`,
  `полша`,
  `полът`,
  `полъх`,
  `полюс`,
  `поляк`,
  `полял`,
  `полян`,
  `полят`,
  `полях`,
  `помак`,
  `помел`,
  `помен`,
  `помие`,
  `помил`,
  `помия`,
  `помля`,
  `помни`,
  `помня`,
  `помощ`,
  `помпа`,
  `помпи`,
  `помпя`,
  `понев`,
  `понор`,
  `пончо`,
  `поора`,
  `пооре`,
  `попей`,
  `попея`,
  `попие`,
  `попий`,
  `попил`,
  `попит`,
  `попия`,
  `попов`,
  `попче`,
  `попът`,
  `поред`,
  `порещ`,
  `порив`,
  `порка`,
  `порна`,
  `порне`,
  `порни`,
  `порно`,
  `порои`,
  `порой`,
  `порок`,
  `пороя`,
  `порта`,
  `порти`,
  `порто`,
  `порше`,
  `порът`,
  `порят`,
  `посев`,
  `посее`,
  `посея`,
  `после`,
  `посмя`,
  `поспа`,
  `поспи`,
  `поспя`,
  `поста`,
  `пости`,
  `постя`,
  `посял`,
  `посях`,
  `поташ`,
  `потая`,
  `потен`,
  `потим`,
  `потир`,
  `потна`,
  `потни`,
  `потно`,
  `поток`,
  `потон`,
  `потоп`,
  `потта`,
  `потят`,
  `поука`,
  `поуки`,
  `поуча`,
  `поучи`,
  `поход`,
  `похот`,
  `почва`,
  `почви`,
  `почек`,
  `почел`,
  `почин`,
  `почит`,
  `почна`,
  `почне`,
  `почни`,
  `почти`,
  `почуя`,
  `пошла`,
  `пошли`,
  `пошло`,
  `пошче`,
  `пошъл`,
  `пощат`,
  `пощем`,
  `пощеш`,
  `пощял`,
  `пощят`,
  `пощях`,
  `поява`,
  `появи`,
  `пояса`,
  `пояси`,
  `права`,
  `прави`,
  `право`,
  `правя`,
  `прага`,
  `прада`,
  `праза`,
  `прайд`,
  `прайм`,
  `прала`,
  `прали`,
  `прана`,
  `пране`,
  `прани`,
  `прасе`,
  `прати`,
  `пратя`,
  `праха`,
  `праша`,
  `праща`,
  `пращи`,
  `пращя`,
  `преби`,
  `преви`,
  `превю`,
  `преда`,
  `преде`,
  `преди`,
  `преки`,
  `прела`,
  `прело`,
  `преля`,
  `преми`,
  `пренс`,
  `преса`,
  `преси`,
  `преча`,
  `пречи`,
  `преял`,
  `преям`,
  `приел`,
  `прием`,
  `приет`,
  `приех`,
  `приза`,
  `прикя`,
  `прима`,
  `прими`,
  `принт`,
  `принц`,
  `прион`,
  `припи`,
  `припя`,
  `прища`,
  `прище`,
  `прищя`,
  `приют`,
  `приям`,
  `проба`,
  `проби`,
  `проза`,
  `прози`,
  `проля`,
  `проми`,
  `промо`,
  `пропи`,
  `проси`,
  `просо`,
  `прост`,
  `прося`,
  `прочу`,
  `проям`,
  `пружи`,
  `пруст`,
  `пръст`,
  `пръта`,
  `пръти`,
  `пряка`,
  `пряко`,
  `пряма`,
  `прями`,
  `прямо`,
  `псалм`,
  `псалт`,
  `псета`,
  `психо`,
  `псков`,
  `псува`,
  `птица`,
  `птици`,
  `птицо`,
  `птича`,
  `птиче`,
  `птичи`,
  `птоза`,
  `пубис`,
  `пудел`,
  `пудра`,
  `пудри`,
  `пудря`,
  `пуйка`,
  `пуйки`,
  `пуйча`,
  `пуйче`,
  `пуйчи`,
  `пукал`,
  `пукам`,
  `пукан`,
  `пукат`,
  `пукащ`,
  `пуква`,
  `пукна`,
  `пукне`,
  `пукот`,
  `пулев`,
  `пулих`,
  `пулиш`,
  `пулке`,
  `пулпа`,
  `пулса`,
  `пулта`,
  `пулът`,
  `пункт`,
  `пунто`,
  `пунша`,
  `пурин`,
  `пусия`,
  `пуска`,
  `пусна`,
  `пусне`,
  `пусни`,
  `пуста`,
  `пусти`,
  `пусто`,
  `путин`,
  `путка`,
  `путки`,
  `путьо`,
  `пуфтя`,
  `пухам`,
  `пухен`,
  `пухна`,
  `пухов`,
  `пухтя`,
  `пухче`,
  `пухчо`,
  `пухът`,
  `пучът`,
  `пушат`,
  `пушач`,
  `пушек`,
  `пушен`,
  `пушещ`,
  `пушил`,
  `пушим`,
  `пушиш`,
  `пушка`,
  `пушки`,
  `пушна`,
  `пущам`,
  `пущат`,
  `пущун`,
  `пуяци`,
  `пчела`,
  `пчели`,
  `пъбът`,
  `пъдар`,
  `пъдиш`,
  `пъдят`,
  `пъзел`,
  `пъзла`,
  `пъзъл`,
  `пъкна`,
  `пъков`,
  `пъкъл`,
  `пълев`,
  `пълен`,
  `пълзи`,
  `пълзя`,
  `пълна`,
  `пълни`,
  `пълно`,
  `пълня`,
  `пълов`,
  `пънка`,
  `пънче`,
  `пъпен`,
  `пъпеш`,
  `пъпка`,
  `пъпки`,
  `пъпли`,
  `пъпля`,
  `пъпна`,
  `пъпни`,
  `пъпно`,
  `пъпче`,
  `пъпът`,
  `първа`,
  `първи`,
  `първо`,
  `пърди`,
  `пърдя`,
  `пържа`,
  `пържи`,
  `пърли`,
  `пърля`,
  `пърша`,
  `пътем`,
  `пътен`,
  `пътна`,
  `пътни`,
  `пътно`,
  `пътят`,
  `пъхай`,
  `пъхам`,
  `пъхат`,
  `пъхаш`,
  `пъхва`,
  `пъхна`,
  `пъхне`,
  `пъхтя`,
  `пъшев`,
  `пясък`,
  `рабат`,
  `рабош`,
  `рабът`,
  `раван`,
  `равда`,
  `равен`,
  `равин`,
  `равна`,
  `равни`,
  `равно`,
  `равня`,
  `радан`,
  `радар`,
  `радва`,
  `радев`,
  `радее`,
  `радей`,
  `радея`,
  `раджа`,
  `раджи`,
  `радиа`,
  `радий`,
  `радин`,
  `радио`,
  `радия`,
  `радка`,
  `радко`,
  `радон`,
  `радуй`,
  `раева`,
  `ражда`,
  `разби`,
  `разви`,
  `развя`,
  `раззе`,
  `разля`,
  `разми`,
  `разни`,
  `разум`,
  `разши`,
  `разям`,
  `раите`,
  `райка`,
  `райко`,
  `райна`,
  `район`,
  `райха`,
  `райчо`,
  `ракел`,
  `ракии`,
  `ракия`,
  `ракла`,
  `ракли`,
  `раков`,
  `ракът`,
  `ралев`,
  `рамбо`,
  `рамка`,
  `рамки`,
  `рампа`,
  `рампи`,
  `ранга`,
  `ранен`,
  `ранил`,
  `раним`,
  `ранна`,
  `ранни`,
  `ранно`,
  `ранчо`,
  `ранят`,
  `рапан`,
  `рапел`,
  `рапър`,
  `рапът`,
  `расла`,
  `расли`,
  `расло`,
  `расна`,
  `расне`,
  `расни`,
  `расов`,
  `раста`,
  `расте`,
  `расти`,
  `расъл`,
  `ратаи`,
  `ратай`,
  `ратая`,
  `рафия`,
  `рафта`,
  `рахат`,
  `рахит`,
  `рачев`,
  `рачел`,
  `рачов`,
  `рашев`,
  `рашка`,
  `рашко`,
  `рашов`,
  `раята`,
  `реала`,
  `реали`,
  `ребра`,
  `ребро`,
  `ребус`,
  `ревал`,
  `реват`,
  `ревен`,
  `ревер`,
  `ревеш`,
  `ревла`,
  `ревна`,
  `ревне`,
  `ревът`,
  `ревящ`,
  `регал`,
  `редей`,
  `реден`,
  `редея`,
  `редиш`,
  `редки`,
  `редна`,
  `редни`,
  `редно`,
  `редов`,
  `редом`,
  `редут`,
  `редче`,
  `редът`,
  `редял`,
  `редят`,
  `реели`,
  `реене`,
  `рееха`,
  `рееща`,
  `реещи`,
  `режат`,
  `режел`,
  `режем`,
  `режех`,
  `режеш`,
  `режещ`,
  `режим`,
  `режия`,
  `резач`,
  `резба`,
  `резби`,
  `резен`,
  `резец`,
  `резил`,
  `резка`,
  `резки`,
  `резне`,
  `резни`,
  `резон`,
  `резус`,
  `резци`,
  `рейва`,
  `рейда`,
  `рейка`,
  `рейки`,
  `рейни`,
  `рейса`,
  `рекат`,
  `рекет`,
  `рекла`,
  `рекли`,
  `рекло`,
  `рекох`,
  `рекъл`,
  `релеф`,
  `релса`,
  `релси`,
  `ремък`,
  `ренде`,
  `рений`,
  `рения`,
  `рента`,
  `ренти`,
  `репей`,
  `репер`,
  `репча`,
  `ресан`,
  `ресен`,
  `ресна`,
  `ресни`,
  `ресор`,
  `ресто`,
  `ретро`,
  `ретур`,
  `ретуш`,
  `рефер`,
  `рехав`,
  `речев`,
  `речем`,
  `речен`,
  `речка`,
  `речна`,
  `речни`,
  `речно`,
  `речта`,
  `решал`,
  `решат`,
  `решел`,
  `решен`,
  `решил`,
  `решим`,
  `реших`,
  `решиш`,
  `рибар`,
  `рибен`,
  `рибка`,
  `рибке`,
  `рибки`,
  `рибна`,
  `рибни`,
  `рибно`,
  `рибок`,
  `ривър`,
  `риган`,
  `ридае`,
  `ридал`,
  `ридая`,
  `ридът`,
  `риело`,
  `риене`,
  `риещи`,
  `рижав`,
  `рижия`,
  `ризка`,
  `ризки`,
  `ризов`,
  `ризон`,
  `рикса`,
  `рикша`,
  `рикши`,
  `рилци`,
  `римен`,
  `римна`,
  `римни`,
  `ринат`,
  `ринга`,
  `ринем`,
  `ринеш`,
  `ринит`,
  `рипал`,
  `рипам`,
  `рипна`,
  `риска`,
  `рисов`,
  `рисът`,
  `ритай`,
  `ритал`,
  `ритам`,
  `ритан`,
  `ритат`,
  `риташ`,
  `ритва`,
  `ритла`,
  `ритли`,
  `ритми`,
  `ритна`,
  `ритне`,
  `ритни`,
  `ритон`,
  `ритъм`,
  `рифов`,
  `рифът`,
  `рицар`,
  `рицин`,
  `робия`,
  `робот`,
  `робът`,
  `ровел`,
  `ровен`,
  `ровех`,
  `ровещ`,
  `ровил`,
  `ровим`,
  `рових`,
  `ровиш`,
  `ровка`,
  `ровък`,
  `ровът`,
  `ровят`,
  `рогат`,
  `рогач`,
  `рогов`,
  `рогоз`,
  `рогош`,
  `рогца`,
  `рогче`,
  `рогът`,
  `родее`,
  `родей`,
  `роден`,
  `родео`,
  `родея`,
  `родий`,
  `родил`,
  `родих`,
  `родиш`,
  `родия`,
  `родна`,
  `родни`,
  `родно`,
  `родов`,
  `родом`,
  `родос`,
  `родът`,
  `родят`,
  `роеве`,
  `роена`,
  `роене`,
  `рожба`,
  `рожби`,
  `рожбо`,
  `рожен`,
  `розов`,
  `ройте`,
  `рокер`,
  `рокли`,
  `рокля`,
  `рокът`,
  `ролев`,
  `ролка`,
  `ролки`,
  `ролце`,
  `роман`,
  `ромба`,
  `ромеи`,
  `ромей`,
  `ромео`,
  `ромея`,
  `ромка`,
  `ромол`,
  `ромон`,
  `ромът`,
  `ронда`,
  `рондо`,
  `ронен`,
  `ронят`,
  `ропот`,
  `ропта`,
  `росев`,
  `росен`,
  `росна`,
  `росни`,
  `росно`,
  `ротен`,
  `ротни`,
  `ротор`,
  `рохка`,
  `рохки`,
  `рохко`,
  `рохък`,
  `рошав`,
  `рошел`,
  `рошла`,
  `рояка`,
  `рояла`,
  `рояли`,
  `рояци`,
  `рубеж`,
  `рубик`,
  `рубин`,
  `рубия`,
  `рубка`,
  `рубла`,
  `рубли`,
  `ругае`,
  `ругай`,
  `ругая`,
  `рудар`,
  `руден`,
  `рудна`,
  `рудни`,
  `рудно`,
  `ружев`,
  `ружин`,
  `ружка`,
  `руина`,
  `руини`,
  `руйна`,
  `руйни`,
  `руйно`,
  `руква`,
  `рукна`,
  `рулон`,
  `рулца`,
  `рулце`,
  `рулят`,
  `румба`,
  `румби`,
  `румен`,
  `румян`,
  `рунда`,
  `рупии`,
  `рупия`,
  `рупор`,
  `рупци`,
  `рупча`,
  `рупче`,
  `русев`,
  `русее`,
  `русей`,
  `русен`,
  `русея`,
  `русин`,
  `русия`,
  `руска`,
  `руски`,
  `руско`,
  `русла`,
  `русло`,
  `рутен`,
  `рутер`,
  `рутил`,
  `рутин`,
  `рухва`,
  `рухна`,
  `рухне`,
  `ручай`,
  `ручам`,
  `ручеи`,
  `ручей`,
  `ручея`,
  `рушат`,
  `рушащ`,
  `рушен`,
  `рушим`,
  `рушиш`,
  `ръбат`,
  `ръбен`,
  `ръбче`,
  `ръбът`,
  `ръгай`,
  `ръгам`,
  `ръгат`,
  `ръгби`,
  `ръгна`,
  `ръжда`,
  `ръжди`,
  `ръжен`,
  `ръжта`,
  `ръкав`,
  `ръмеж`,
  `ръмжа`,
  `ръмжи`,
  `ръсел`,
  `ръсил`,
  `ръсиш`,
  `ръста`,
  `ръсят`,
  `рътът`,
  `ръфам`,
  `ръфат`,
  `ръчен`,
  `ръчка`,
  `ръчки`,
  `ръчна`,
  `ръчни`,
  `ръчно`,
  `рядка`,
  `рядко`,
  `рядък`,
  `рязал`,
  `рязан`,
  `рязах`,
  `рязка`,
  `рязко`,
  `рязък`,
  `сабин`,
  `савак`,
  `саван`,
  `савев`,
  `савин`,
  `савов`,
  `савоя`,
  `савчо`,
  `садим`,
  `садиш`,
  `садху`,
  `садят`,
  `сажда`,
  `сажди`,
  `сажен`,
  `саите`,
  `сайта`,
  `сакар`,
  `сакат`,
  `сакче`,
  `сакъз`,
  `сакън`,
  `салам`,
  `салаш`,
  `салда`,
  `салдо`,
  `салеп`,
  `салон`,
  `салса`,
  `салси`,
  `салта`,
  `салто`,
  `салца`,
  `салът`,
  `салют`,
  `самар`,
  `самба`,
  `самби`,
  `самбо`,
  `самец`,
  `самия`,
  `самка`,
  `самки`,
  `самоа`,
  `самум`,
  `самун`,
  `самур`,
  `самци`,
  `санго`,
  `санкт`,
  `санта`,
  `санто`,
  `санът`,
  `сапун`,
  `сараи`,
  `сарай`,
  `сараф`,
  `сарач`,
  `сарая`,
  `сарма`,
  `сарми`,
  `сатва`,
  `сатен`,
  `сатир`,
  `сатър`,
  `сауна`,
  `саунд`,
  `сауни`,
  `сахан`,
  `сахат`,
  `сачак`,
  `сачма`,
  `сачми`,
  `сашев`,
  `сашка`,
  `сашко`,
  `сашов`,
  `саята`,
  `сбера`,
  `сбере`,
  `сбива`,
  `сбием`,
  `сбили`,
  `сбира`,
  `сбита`,
  `сбити`,
  `сбито`,
  `сбиха`,
  `сбият`,
  `сбора`,
  `сборя`,
  `сбрал`,
  `сбран`,
  `сбруи`,
  `сбруя`,
  `свада`,
  `свади`,
  `свако`,
  `свали`,
  `сваля`,
  `свари`,
  `сваря`,
  `свата`,
  `свате`,
  `свати`,
  `сватя`,
  `сведа`,
  `сведе`,
  `сведи`,
  `свежа`,
  `свежи`,
  `свежо`,
  `свела`,
  `свели`,
  `свело`,
  `свени`,
  `свеня`,
  `свери`,
  `сверя`,
  `света`,
  `свети`,
  `свето`,
  `светя`,
  `свещи`,
  `свива`,
  `свиди`,
  `свидя`,
  `свием`,
  `свика`,
  `свила`,
  `свили`,
  `свило`,
  `свине`,
  `свини`,
  `свиня`,
  `свира`,
  `свири`,
  `свиря`,
  `свита`,
  `свити`,
  `свито`,
  `свиха`,
  `свише`,
  `свият`,
  `своге`,
  `свода`,
  `своде`,
  `своят`,
  `сврат`,
  `свръх`,
  `свъся`,
  `свяст`,
  `свята`,
  `святи`,
  `свято`,
  `сгази`,
  `сгазя`,
  `сгафи`,
  `сгафя`,
  `сгащя`,
  `сгода`,
  `сгоди`,
  `сгодя`,
  `сгрея`,
  `сгуша`,
  `сгъва`,
  `сгъна`,
  `сгъне`,
  `сдава`,
  `сдавя`,
  `сдаде`,
  `сдала`,
  `сдвоя`,
  `сдоби`,
  `сдръж`,
  `сдуха`,
  `сдуша`,
  `сеанс`,
  `севар`,
  `севда`,
  `север`,
  `севов`,
  `сегиз`,
  `седан`,
  `седеж`,
  `седем`,
  `седеф`,
  `седим`,
  `седиш`,
  `седла`,
  `седло`,
  `седма`,
  `седми`,
  `седмо`,
  `седна`,
  `седне`,
  `седни`,
  `седял`,
  `седят`,
  `седях`,
  `седящ`,
  `сеела`,
  `сеели`,
  `сеело`,
  `сеене`,
  `сеете`,
  `сееха`,
  `сееше`,
  `сееща`,
  `сеещи`,
  `сезон`,
  `сеира`,
  `сеири`,
  `сейма`,
  `сейнт`,
  `сейте`,
  `сейфа`,
  `секам`,
  `секат`,
  `секач`,
  `секва`,
  `секли`,
  `секна`,
  `секса`,
  `секси`,
  `секта`,
  `секти`,
  `селда`,
  `селен`,
  `селия`,
  `селца`,
  `селце`,
  `селци`,
  `селча`,
  `селяк`,
  `селям`,
  `семит`,
  `семка`,
  `семки`,
  `семов`,
  `сенат`,
  `сенен`,
  `сенки`,
  `сенна`,
  `сенно`,
  `сенце`,
  `сепва`,
  `сепии`,
  `сепия`,
  `сепна`,
  `сепне`,
  `серат`,
  `серво`,
  `серем`,
  `серен`,
  `серив`,
  `серии`,
  `серия`,
  `серни`,
  `серум`,
  `сесии`,
  `сесия`,
  `сетен`,
  `сетер`,
  `сетех`,
  `сетил`,
  `сетим`,
  `сетих`,
  `сетиш`,
  `сетна`,
  `сетне`,
  `сетни`,
  `сетре`,
  `сетът`,
  `сетят`,
  `сефте`,
  `сецва`,
  `сечал`,
  `сечащ`,
  `сечем`,
  `сечен`,
  `сечеш`,
  `сечко`,
  `сечта`,
  `сешън`,
  `сещай`,
  `сещал`,
  `сещам`,
  `сещат`,
  `сещах`,
  `сещаш`,
  `сеяча`,
  `сеячи`,
  `сибир`,
  `сивее`,
  `сивен`,
  `сивея`,
  `сивик`,
  `сивия`,
  `сивов`,
  `сигли`,
  `сигма`,
  `сидер`,
  `сидни`,
  `сиера`,
  `сизал`,
  `сийка`,
  `сикхи`,
  `силаж`,
  `силва`,
  `силви`,
  `силен`,
  `силим`,
  `силна`,
  `силни`,
  `силно`,
  `силов`,
  `силоз`,
  `силом`,
  `силон`,
  `симид`,
  `симов`,
  `синап`,
  `синее`,
  `синей`,
  `синец`,
  `синея`,
  `синия`,
  `синка`,
  `синко`,
  `синов`,
  `синод`,
  `синор`,
  `синус`,
  `синци`,
  `синче`,
  `синът`,
  `синьо`,
  `синял`,
  `сипах`,
  `сипва`,
  `сипеи`,
  `сипей`,
  `сипел`,
  `сипец`,
  `сипеш`,
  `сипея`,
  `сипка`,
  `сипки`,
  `сипна`,
  `сипни`,
  `сипят`,
  `сирак`,
  `сирен`,
  `сиреч`,
  `сирил`,
  `сирим`,
  `сирия`,
  `сирма`,
  `сирна`,
  `сирни`,
  `сироп`,
  `ситен`,
  `сития`,
  `ситна`,
  `ситни`,
  `ситно`,
  `ситня`,
  `сиукс`,
  `сифон`,
  `сияен`,
  `сияеш`,
  `сияещ`,
  `сияло`,
  `сияят`,
  `скайп`,
  `скала`,
  `скали`,
  `скалп`,
  `скапа`,
  `скапе`,
  `скапя`,
  `скара`,
  `скари`,
  `ската`,
  `скаут`,
  `скача`,
  `скачи`,
  `скейт`,
  `скеле`,
  `скеля`,
  `скеча`,
  `скиор`,
  `скита`,
  `ските`,
  `скити`,
  `скифа`,
  `скица`,
  `скици`,
  `склад`,
  `склон`,
  `скоба`,
  `скоби`,
  `скова`,
  `скока`,
  `скопи`,
  `скопя`,
  `скоро`,
  `скося`,
  `скота`,
  `скоуш`,
  `скоча`,
  `скочи`,
  `скрап`,
  `скреж`,
  `скреч`,
  `скрие`,
  `скрий`,
  `скрил`,
  `скрин`,
  `скрит`,
  `скрих`,
  `скрия`,
  `скрои`,
  `скроя`,
  `скръб`,
  `скрът`,
  `скръц`,
  `скуба`,
  `скубе`,
  `скуби`,
  `скубя`,
  `скука`,
  `скула`,
  `скули`,
  `скуош`,
  `скута`,
  `скъпа`,
  `скъпи`,
  `скъпо`,
  `скъпя`,
  `скъса`,
  `скъси`,
  `скъся`,
  `скъта`,
  `слаба`,
  `слаби`,
  `слабо`,
  `слава`,
  `слави`,
  `славо`,
  `славя`,
  `слага`,
  `сладя`,
  `слайд`,
  `слама`,
  `слами`,
  `слана`,
  `слани`,
  `сласт`,
  `следа`,
  `следи`,
  `следя`,
  `слеем`,
  `слееш`,
  `слеза`,
  `слезе`,
  `слели`,
  `сленг`,
  `слепи`,
  `слепя`,
  `слети`,
  `слетя`,
  `слеят`,
  `слива`,
  `сливи`,
  `сливо`,
  `слиза`,
  `слова`,
  `слово`,
  `слога`,
  `сложа`,
  `сложи`,
  `сломи`,
  `сломя`,
  `слона`,
  `слоне`,
  `слота`,
  `слоят`,
  `слуга`,
  `слуги`,
  `служа`,
  `служи`,
  `слука`,
  `слуха`,
  `случа`,
  `случи`,
  `слуша`,
  `слъга`,
  `слъжа`,
  `слъже`,
  `слъжи`,
  `слюда`,
  `слюди`,
  `сляга`,
  `сляза`,
  `сляла`,
  `сляло`,
  `сляпа`,
  `сляпо`,
  `слята`,
  `слято`,
  `сляха`,
  `смажа`,
  `смаже`,
  `смали`,
  `смаля`,
  `смарт`,
  `смаян`,
  `смаят`,
  `смаях`,
  `смеел`,
  `смеем`,
  `смеех`,
  `смееш`,
  `смеещ`,
  `смела`,
  `смели`,
  `смело`,
  `смеля`,
  `смени`,
  `сменя`,
  `смерч`,
  `смеси`,
  `смеся`,
  `смета`,
  `смете`,
  `смеха`,
  `смеят`,
  `смила`,
  `смили`,
  `смило`,
  `смиля`,
  `смири`,
  `смиря`,
  `смита`,
  `смити`,
  `смиха`,
  `смлял`,
  `смлян`,
  `смлях`,
  `смога`,
  `смока`,
  `смола`,
  `смоли`,
  `смоля`,
  `смоци`,
  `смрад`,
  `смута`,
  `смути`,
  `смутя`,
  `смуча`,
  `смуче`,
  `смучи`,
  `смуша`,
  `смъдя`,
  `смърт`,
  `смърф`,
  `смърч`,
  `смяла`,
  `смяна`,
  `смята`,
  `смяха`,
  `снага`,
  `снаги`,
  `снадя`,
  `снакс`,
  `снася`,
  `снаха`,
  `снахи`,
  `снахо`,
  `снега`,
  `снежа`,
  `снела`,
  `снели`,
  `снело`,
  `снема`,
  `снеме`,
  `снеми`,
  `снеса`,
  `снесе`,
  `снета`,
  `снети`,
  `снето`,
  `снеха`,
  `снижа`,
  `снижи`,
  `снизя`,
  `снима`,
  `сниша`,
  `сниши`,
  `сноби`,
  `снова`,
  `снове`,
  `снопа`,
  `снопи`,
  `сноша`,
  `сноши`,
  `снощи`,
  `сняга`,
  `соаре`,
  `совна`,
  `соден`,
  `содов`,
  `содом`,
  `соева`,
  `соеве`,
  `соеви`,
  `соево`,
  `сойка`,
  `сойки`,
  `сойна`,
  `сойни`,
  `сокай`,
  `сокак`,
  `сокет`,
  `сокол`,
  `сокър`,
  `сокът`,
  `солен`,
  `солиш`,
  `солна`,
  `солни`,
  `солно`,
  `солов`,
  `солта`,
  `солун`,
  `солят`,
  `сомов`,
  `сомче`,
  `сомът`,
  `сонар`,
  `сонда`,
  `сонди`,
  `сонев`,
  `сонет`,
  `сонма`,
  `сопам`,
  `сопва`,
  `сопна`,
  `сопне`,
  `сопол`,
  `сопот`,
  `сорбе`,
  `сорго`,
  `сорса`,
  `сорта`,
  `сосът`,
  `сотир`,
  `соула`,
  `софит`,
  `софия`,
  `софка`,
  `софра`,
  `софри`,
  `софта`,
  `сочат`,
  `сочел`,
  `сочен`,
  `сочех`,
  `сочещ`,
  `сочим`,
  `сочиш`,
  `сочна`,
  `сочни`,
  `сочно`,
  `соята`,
  `спада`,
  `спази`,
  `спазя`,
  `спала`,
  `спали`,
  `спало`,
  `спама`,
  `спане`,
  `спари`,
  `спаря`,
  `спаси`,
  `спася`,
  `спаха`,
  `спейн`,
  `спейс`,
  `спека`,
  `спели`,
  `спете`,
  `спеца`,
  `спеце`,
  `спече`,
  `спеша`,
  `спеше`,
  `спеши`,
  `спина`,
  `спипа`,
  `спира`,
  `спирт`,
  `спите`,
  `спица`,
  `спици`,
  `спиша`,
  `сплав`,
  `сплит`,
  `сплул`,
  `сплут`,
  `споен`,
  `спора`,
  `спори`,
  `спорт`,
  `споря`,
  `спрат`,
  `спред`,
  `спрей`,
  `спрем`,
  `спреш`,
  `спрея`,
  `спрял`,
  `спрян`,
  `спрях`,
  `спука`,
  `спуща`,
  `спъва`,
  `спъна`,
  `спъне`,
  `спъни`,
  `спяла`,
  `спяло`,
  `спяха`,
  `спяща`,
  `спящи`,
  `спящо`,
  `срази`,
  `сразя`,
  `срало`,
  `срама`,
  `срами`,
  `срамя`,
  `срана`,
  `сране`,
  `срани`,
  `среда`,
  `среди`,
  `срежа`,
  `среже`,
  `срежи`,
  `среза`,
  `срета`,
  `среша`,
  `среше`,
  `среши`,
  `среща`,
  `срещи`,
  `срещу`,
  `срива`,
  `срина`,
  `срине`,
  `срича`,
  `сроди`,
  `сродя`,
  `срока`,
  `срути`,
  `срутя`,
  `сряда`,
  `сряза`,
  `става`,
  `стави`,
  `стада`,
  `стадо`,
  `стаев`,
  `стаен`,
  `стажа`,
  `стаза`,
  `стаил`,
  `стайл`,
  `стана`,
  `стане`,
  `стани`,
  `стапя`,
  `стара`,
  `стари`,
  `старо`,
  `старт`,
  `ствол`,
  `стека`,
  `стела`,
  `стеле`,
  `стели`,
  `стелт`,
  `стеля`,
  `стена`,
  `стенд`,
  `стене`,
  `стени`,
  `стент`,
  `степа`,
  `степи`,
  `стига`,
  `стика`,
  `стила`,
  `стило`,
  `стиха`,
  `стича`,
  `стоев`,
  `стоеж`,
  `стожа`,
  `стоик`,
  `стоил`,
  `стоим`,
  `стоиш`,
  `стойо`,
  `стока`,
  `стоки`,
  `стола`,
  `стоок`,
  `стопа`,
  `стопи`,
  `стопя`,
  `стори`,
  `сторя`,
  `стоте`,
  `стоял`,
  `стоян`,
  `стоят`,
  `стоях`,
  `стоящ`,
  `страж`,
  `страх`,
  `стрес`,
  `стрий`,
  `стрит`,
  `стрия`,
  `строг`,
  `строи`,
  `строй`,
  `строя`,
  `струг`,
  `струи`,
  `струй`,
  `струя`,
  `стръв`,
  `стрък`,
  `студа`,
  `ступа`,
  `ступи`,
  `стълб`,
  `стънт`,
  `стъпи`,
  `стъпя`,
  `стяга`,
  `суапа`,
  `судан`,
  `суета`,
  `суети`,
  `сузан`,
  `суинг`,
  `суифт`,
  `сукна`,
  `сукно`,
  `сукре`,
  `сулка`,
  `сумер`,
  `сумка`,
  `сумки`,
  `сумтя`,
  `сунит`,
  `супен`,
  `супер`,
  `супин`,
  `сурат`,
  `сурва`,
  `суров`,
  `сусам`,
  `сутра`,
  `сутри`,
  `суфле`,
  `сухар`,
  `сухия`,
  `сучат`,
  `сучем`,
  `сучеш`,
  `сушав`,
  `сушат`,
  `сушен`,
  `суяци`,
  `сфера`,
  `сфери`,
  `схема`,
  `схеми`,
  `схима`,
  `сцена`,
  `сцени`,
  `сцепи`,
  `сцепя`,
  `счела`,
  `счели`,
  `счело`,
  `счета`,
  `счете`,
  `счита`,
  `счука`,
  `счупи`,
  `счупя`,
  `събев`,
  `събор`,
  `събра`,
  `събуе`,
  `събух`,
  `събуя`,
  `съвет`,
  `съвзе`,
  `съдба`,
  `съдби`,
  `съдел`,
  `съден`,
  `съдии`,
  `съдил`,
  `съдим`,
  `съдиш`,
  `съдия`,
  `съдов`,
  `съдче`,
  `съдът`,
  `съдят`,
  `съева`,
  `съево`,
  `съеша`,
  `съеши`,
  `съзра`,
  `съзре`,
  `съзря`,
  `сълза`,
  `сълзи`,
  `сълзя`,
  `сълов`,
  `съмва`,
  `съмна`,
  `съмне`,
  `сънен`,
  `сънла`,
  `сънна`,
  `сънни`,
  `сънно`,
  `сънчо`,
  `сънят`,
  `сърба`,
  `сърби`,
  `сърбя`,
  `сърди`,
  `сърдя`,
  `сърма`,
  `сърми`,
  `сърна`,
  `сърне`,
  `сърни`,
  `сърпа`,
  `сърфа`,
  `сърца`,
  `сърце`,
  `съсед`,
  `съсел`,
  `съска`,
  `съсъд`,
  `съсък`,
  `съумя`,
  `съхна`,
  `съхне`,
  `съчка`,
  `съчки`,
  `съчма`,
  `съчми`,
  `съшие`,
  `съшит`,
  `съшия`,
  `същия`,
  `съюза`,
  `съюзи`,
  `съюзя`,
  `сюжет`,
  `сюита`,
  `сюити`,
  `сюнет`,
  `сюрии`,
  `сюрия`,
  `сядай`,
  `сядал`,
  `сядам`,
  `сядат`,
  `сядаш`,
  `сякаш`,
  `сякла`,
  `сякло`,
  `сякох`,
  `сякъл`,
  `сянка`,
  `сярна`,
  `сярно`,
  `сярци`,
  `табак`,
  `табан`,
  `табла`,
  `табли`,
  `табло`,
  `табор`,
  `табун`,
  `табът`,
  `таван`,
  `тагът`,
  `таели`,
  `таена`,
  `таене`,
  `таеше`,
  `тазов`,
  `тазът`,
  `таила`,
  `таили`,
  `таите`,
  `таити`,
  `тайга`,
  `тайги`,
  `таймс`,
  `тайна`,
  `тайни`,
  `тайно`,
  `тайфа`,
  `тайфи`,
  `такев`,
  `такса`,
  `такси`,
  `такта`,
  `такъв`,
  `такъм`,
  `талаз`,
  `талаш`,
  `талев`,
  `талер`,
  `талии`,
  `талий`,
  `талин`,
  `талия`,
  `талка`,
  `талон`,
  `талпа`,
  `талпи`,
  `талус`,
  `талян`,
  `таман`,
  `тамас`,
  `тамън`,
  `тамян`,
  `танга`,
  `танго`,
  `танев`,
  `танец`,
  `танин`,
  `танка`,
  `танов`,
  `танто`,
  `танца`,
  `танци`,
  `таньо`,
  `тапет`,
  `тапии`,
  `тапир`,
  `тапия`,
  `таран`,
  `тараш`,
  `тарга`,
  `тарги`,
  `тасев`,
  `тасос`,
  `тасът`,
  `татко`,
  `татов`,
  `татри`,
  `татул`,
  `татус`,
  `татък`,
  `тауър`,
  `тафта`,
  `тахан`,
  `тахов`,
  `тачат`,
  `тачев`,
  `тачел`,
  `тачен`,
  `тачил`,
  `тачим`,
  `тачиш`,
  `ташак`,
  `ташев`,
  `таящи`,
  `твари`,
  `твори`,
  `творя`,
  `твоят`,
  `твърд`,
  `тегел`,
  `тегла`,
  `тегли`,
  `тегло`,
  `тегля`,
  `тегна`,
  `тегне`,
  `тежал`,
  `тежат`,
  `тежащ`,
  `тежиш`,
  `тежка`,
  `тежки`,
  `тежко`,
  `тежък`,
  `тезей`,
  `тезис`,
  `теист`,
  `тейко`,
  `текат`,
  `теква`,
  `текла`,
  `текли`,
  `текло`,
  `текме`,
  `текна`,
  `текне`,
  `текст`,
  `текущ`,
  `текъл`,
  `текът`,
  `телен`,
  `телец`,
  `телиш`,
  `телом`,
  `телта`,
  `телца`,
  `телце`,
  `телци`,
  `телче`,
  `телът`,
  `теляк`,
  `темел`,
  `темза`,
  `темпа`,
  `темпо`,
  `тенар`,
  `тенев`,
  `тении`,
  `тенис`,
  `тения`,
  `тенко`,
  `тенор`,
  `тента`,
  `тенти`,
  `тенчо`,
  `теньо`,
  `тепам`,
  `тепан`,
  `тепих`,
  `терен`,
  `терза`,
  `терор`,
  `терца`,
  `терци`,
  `тесам`,
  `тесан`,
  `тесен`,
  `тесла`,
  `тесли`,
  `тесни`,
  `теста`,
  `тесте`,
  `тесто`,
  `тетка`,
  `техен`,
  `техни`,
  `техно`,
  `течал`,
  `течащ`,
  `течен`,
  `течна`,
  `течни`,
  `течно`,
  `течът`,
  `тешел`,
  `тешен`,
  `тиара`,
  `тиари`,
  `тибет`,
  `тибия`,
  `тибър`,
  `тиган`,
  `тигел`,
  `тигре`,
  `тигри`,
  `тигър`,
  `тийма`,
  `тийна`,
  `тикал`,
  `тикам`,
  `тикат`,
  `тикаш`,
  `тиква`,
  `тикви`,
  `тикла`,
  `тикли`,
  `тикна`,
  `тиков`,
  `тиксо`,
  `тикът`,
  `тилда`,
  `тилди`,
  `тилев`,
  `тилен`,
  `тилна`,
  `тилни`,
  `тилно`,
  `тилов`,
  `тилът`,
  `тимев`,
  `тимов`,
  `тимор`,
  `тимпу`,
  `тимус`,
  `тимът`,
  `тинев`,
  `тинка`,
  `тинко`,
  `типаж`,
  `типов`,
  `типче`,
  `типът`,
  `тираж`,
  `тиран`,
  `тирол`,
  `тисов`,
  `титан`,
  `титла`,
  `титли`,
  `титра`,
  `титри`,
  `титул`,
  `тифус`,
  `тихия`,
  `тихов`,
  `тихом`,
  `тичай`,
  `тичал`,
  `тичам`,
  `тичат`,
  `тичах`,
  `тичаш`,
  `тичащ`,
  `тлака`,
  `тлаки`,
  `тлееш`,
  `тлеещ`,
  `тлеят`,
  `тлъст`,
  `товар`,
  `тогаз`,
  `тогиз`,
  `тодор`,
  `тоест`,
  `токай`,
  `токио`,
  `токов`,
  `токче`,
  `токът`,
  `толос`,
  `томов`,
  `томче`,
  `томът`,
  `тонаж`,
  `тонга`,
  `тонев`,
  `тонен`,
  `тонер`,
  `тоник`,
  `тонка`,
  `тонна`,
  `тонни`,
  `тонно`,
  `тонов`,
  `тонус`,
  `тончо`,
  `тонът`,
  `тоньо`,
  `топаз`,
  `топва`,
  `топен`,
  `топим`,
  `топка`,
  `топки`,
  `топла`,
  `топли`,
  `топло`,
  `топля`,
  `топна`,
  `топор`,
  `топуз`,
  `топче`,
  `топчо`,
  `топъл`,
  `топът`,
  `топят`,
  `топящ`,
  `торба`,
  `торби`,
  `торен`,
  `торий`,
  `торил`,
  `ториш`,
  `тория`,
  `торна`,
  `торни`,
  `торно`,
  `торов`,
  `торса`,
  `торта`,
  `торти`,
  `торфа`,
  `торът`,
  `торят`,
  `тоста`,
  `тотев`,
  `тотем`,
  `тотка`,
  `тотьо`,
  `точат`,
  `точев`,
  `точен`,
  `точил`,
  `точим`,
  `точиш`,
  `точка`,
  `точки`,
  `точна`,
  `точни`,
  `точно`,
  `тошев`,
  `тошко`,
  `тояга`,
  `тояги`,
  `траве`,
  `траел`,
  `траем`,
  `траен`,
  `траех`,
  `траеш`,
  `траещ`,
  `трака`,
  `траки`,
  `тракт`,
  `трала`,
  `транс`,
  `транш`,
  `трапа`,
  `трапе`,
  `трасе`,
  `траур`,
  `траша`,
  `траял`,
  `траят`,
  `треба`,
  `треби`,
  `требя`,
  `трева`,
  `треви`,
  `трезв`,
  `трека`,
  `трела`,
  `трели`,
  `трема`,
  `трена`,
  `тренд`,
  `трепе`,
  `трепи`,
  `трепя`,
  `треса`,
  `тресе`,
  `треси`,
  `трета`,
  `трети`,
  `трето`,
  `трефа`,
  `трефи`,
  `трещи`,
  `трещя`,
  `триас`,
  `трием`,
  `триех`,
  `триеш`,
  `трика`,
  `трико`,
  `трила`,
  `трили`,
  `трило`,
  `трима`,
  `трина`,
  `трини`,
  `триод`,
  `триок`,
  `трион`,
  `триор`,
  `трита`,
  `трите`,
  `трити`,
  `трито`,
  `трица`,
  `трици`,
  `трият`,
  `трови`,
  `тровя`,
  `троев`,
  `троен`,
  `трола`,
  `тромб`,
  `трона`,
  `тропа`,
  `троха`,
  `трохи`,
  `троша`,
  `троши`,
  `трояк`,
  `троян`,
  `труда`,
  `труди`,
  `трупа`,
  `трупи`,
  `труса`,
  `труфя`,
  `тръба`,
  `тръби`,
  `тръбя`,
  `тръна`,
  `тръни`,
  `тръса`,
  `тръст`,
  `трюма`,
  `туден`,
  `туида`,
  `туист`,
  `туите`,
  `туйто`,
  `тулев`,
  `тулен`,
  `тулий`,
  `тулум`,
  `тулуп`,
  `тумба`,
  `тумби`,
  `тумор`,
  `тунел`,
  `тунер`,
  `тунис`,
  `тупай`,
  `тупал`,
  `тупам`,
  `тупан`,
  `тупат`,
  `тупва`,
  `тупик`,
  `тупка`,
  `тупна`,
  `тупне`,
  `тупти`,
  `туптя`,
  `турбо`,
  `турел`,
  `турен`,
  `турил`,
  `турим`,
  `турих`,
  `туриш`,
  `турия`,
  `турна`,
  `турне`,
  `турни`,
  `турта`,
  `турук`,
  `турци`,
  `турча`,
  `турче`,
  `турчи`,
  `турът`,
  `турял`,
  `турям`,
  `турян`,
  `турят`,
  `тутам`,
  `тутев`,
  `тутка`,
  `тухла`,
  `тухли`,
  `тучен`,
  `тучна`,
  `тучни`,
  `тучно`,
  `тушът`,
  `туята`,
  `тъжах`,
  `тъжба`,
  `тъжен`,
  `тъжна`,
  `тъжни`,
  `тъжно`,
  `тъкал`,
  `тъкан`,
  `тъкат`,
  `тъках`,
  `тъкач`,
  `тъкмо`,
  `тъкмя`,
  `тълпа`,
  `тълпи`,
  `тълпя`,
  `тъмен`,
  `тъмна`,
  `тъмни`,
  `тъмно`,
  `тъмня`,
  `тънат`,
  `тънел`,
  `тънем`,
  `тънеш`,
  `тънещ`,
  `тънея`,
  `тънка`,
  `тънки`,
  `тънко`,
  `тънтя`,
  `тънък`,
  `тъпак`,
  `тъпан`,
  `тъпен`,
  `тъпея`,
  `тъпия`,
  `тъпка`,
  `тъпни`,
  `тъпча`,
  `тъпче`,
  `тъпчо`,
  `търга`,
  `търка`,
  `търна`,
  `търне`,
  `търпи`,
  `търпя`,
  `търси`,
  `търся`,
  `търтя`,
  `търча`,
  `търчи`,
  `търън`,
  `тъста`,
  `тътен`,
  `тътна`,
  `тътря`,
  `тъчал`,
  `тъчем`,
  `тъчеш`,
  `тъчът`,
  `тюдор`,
  `тюлен`,
  `тюрка`,
  `тюрки`,
  `тюрма`,
  `тютюн`,
  `тягов`,
  `тялом`,
  `тясна`,
  `тясно`,
  `тяхна`,
  `тяхно`,
  `убеди`,
  `убедя`,
  `убива`,
  `убиел`,
  `убием`,
  `убиец`,
  `убиеш`,
  `убила`,
  `убили`,
  `убило`,
  `убита`,
  `убити`,
  `убито`,
  `убиха`,
  `убият`,
  `убода`,
  `убоде`,
  `убола`,
  `уважа`,
  `уважи`,
  `увери`,
  `уверя`,
  `увеся`,
  `увива`,
  `увила`,
  `увили`,
  `увило`,
  `увира`,
  `увита`,
  `увити`,
  `увито`,
  `увият`,
  `увода`,
  `уводи`,
  `уврат`,
  `уврем`,
  `увреш`,
  `уврял`,
  `угади`,
  `угадя`,
  `угари`,
  `угаси`,
  `угася`,
  `угнил`,
  `угния`,
  `угода`,
  `угоди`,
  `угодя`,
  `угоен`,
  `угоят`,
  `удава`,
  `удави`,
  `удавя`,
  `удаде`,
  `удала`,
  `удало`,
  `удара`,
  `удари`,
  `ударя`,
  `удача`,
  `удачи`,
  `удвои`,
  `удвоя`,
  `удиви`,
  `удивя`,
  `удръж`,
  `удряй`,
  `удрял`,
  `удрям`,
  `удрян`,
  `удрят`,
  `удряш`,
  `удуша`,
  `удуши`,
  `уедря`,
  `уейва`,
  `уейлс`,
  `ужаса`,
  `ужаси`,
  `ужася`,
  `ужиля`,
  `ужким`,
  `узбек`,
  `узнае`,
  `узнал`,
  `узнах`,
  `узная`,
  `узово`,
  `узото`,
  `узрее`,
  `узрея`,
  `узрял`,
  `уиски`,
  `уйгур`,
  `укажа`,
  `укаже`,
  `указа`,
  `укази`,
  `уклеи`,
  `уклей`,
  `уклея`,
  `уклон`,
  `укори`,
  `укоря`,
  `укрие`,
  `укрил`,
  `укрия`,
  `улава`,
  `улавя`,
  `улама`,
  `улеми`,
  `улеят`,
  `улика`,
  `улики`,
  `улица`,
  `улици`,
  `улича`,
  `уличи`,
  `улкус`,
  `улова`,
  `улови`,
  `уловя`,
  `ултра`,
  `улука`,
  `улуци`,
  `улуча`,
  `улучи`,
  `уляга`,
  `умаля`,
  `умеел`,
  `умееш`,
  `умеещ`,
  `умела`,
  `умели`,
  `умело`,
  `умеря`,
  `умеси`,
  `умеся`,
  `умеят`,
  `умива`,
  `умиеш`,
  `умило`,
  `умиля`,
  `умира`,
  `умири`,
  `умиря`,
  `умник`,
  `умния`,
  `умове`,
  `умора`,
  `умори`,
  `уморя`,
  `умрат`,
  `умрем`,
  `умреш`,
  `умрял`,
  `умрях`,
  `умува`,
  `унася`,
  `унеса`,
  `унесе`,
  `униат`,
  `унива`,
  `унижа`,
  `унижи`,
  `унизи`,
  `унизя`,
  `унила`,
  `унили`,
  `унило`,
  `унука`,
  `унции`,
  `унция`,
  `унчев`,
  `упада`,
  `уплах`,
  `упоен`,
  `упора`,
  `упори`,
  `упрек`,
  `упъти`,
  `упътя`,
  `урана`,
  `урани`,
  `уреда`,
  `уреди`,
  `уредя`,
  `урина`,
  `урини`,
  `урода`,
  `уроди`,
  `урока`,
  `уроки`,
  `урони`,
  `уроня`,
  `уроци`,
  `урсус`,
  `усвои`,
  `усвой`,
  `усвоя`,
  `уседя`,
  `усета`,
  `усети`,
  `усетя`,
  `усеща`,
  `усили`,
  `усиля`,
  `усоен`,
  `успал`,
  `успах`,
  `успее`,
  `успех`,
  `успея`,
  `успим`,
  `успиш`,
  `успял`,
  `успят`,
  `успях`,
  `устав`,
  `устат`,
  `устен`,
  `устие`,
  `устия`,
  `устна`,
  `устни`,
  `устно`,
  `устои`,
  `устой`,
  `устоя`,
  `усуча`,
  `утаен`,
  `утаят`,
  `утеха`,
  `утехи`,
  `утеша`,
  `утеши`,
  `утоли`,
  `утоля`,
  `утрая`,
  `утрин`,
  `утрои`,
  `утроя`,
  `ухаел`,
  `ухаен`,
  `ухаеш`,
  `ухаещ`,
  `ухане`,
  `ухапа`,
  `ухапе`,
  `ухапи`,
  `ухапя`,
  `ухаят`,
  `ухнем`,
  `ухото`,
  `уцели`,
  `уцеля`,
  `участ`,
  `учаща`,
  `учащи`,
  `учела`,
  `учели`,
  `учело`,
  `учена`,
  `учене`,
  `учени`,
  `учено`,
  `учете`,
  `учеха`,
  `учеше`,
  `учеща`,
  `учещи`,
  `учещо`,
  `учила`,
  `учили`,
  `учило`,
  `учите`,
  `учиха`,
  `учком`,
  `учкур`,
  `учтив`,
  `учуди`,
  `учудя`,
  `ушата`,
  `ушати`,
  `ушева`,
  `ушето`,
  `ушива`,
  `ушила`,
  `ушили`,
  `ушита`,
  `ушите`,
  `ушити`,
  `ушито`,
  `ушият`,
  `ушния`,
  `ущърб`,
  `уютен`,
  `уютна`,
  `уютни`,
  `уютно`,
  `уязви`,
  `уязвя`,
  `уясни`,
  `уясня`,
  `фабии`,
  `фабия`,
  `фагот`,
  `фазан`,
  `фазер`,
  `фазис`,
  `фазов`,
  `файда`,
  `файла`,
  `факел`,
  `факир`,
  `факия`,
  `факла`,
  `факли`,
  `факса`,
  `факта`,
  `факти`,
  `фалит`,
  `фалос`,
  `фалта`,
  `фалша`,
  `фалът`,
  `фанта`,
  `фанте`,
  `фанти`,
  `фарад`,
  `фаров`,
  `фарса`,
  `фарси`,
  `фарът`,
  `фасет`,
  `фасон`,
  `фасул`,
  `фатва`,
  `фатви`,
  `фаула`,
  `фауна`,
  `фауни`,
  `фаянс`,
  `федев`,
  `федер`,
  `феите`,
  `фейса`,
  `фемур`,
  `фенер`,
  `фенка`,
  `фенки`,
  `фенол`,
  `фенът`,
  `ферма`,
  `ферми`,
  `феста`,
  `фесче`,
  `фесът`,
  `фетва`,
  `фетви`,
  `фетиш`,
  `фетус`,
  `фешън`,
  `феята`,
  `фиала`,
  `фиали`,
  `фиати`,
  `фибра`,
  `фибри`,
  `фибър`,
  `фидел`,
  `фидер`,
  `фиджи`,
  `фиева`,
  `фиеви`,
  `фиево`,
  `физик`,
  `фиков`,
  `фикус`,
  `филев`,
  `филиз`,
  `филии`,
  `филип`,
  `филия`,
  `филка`,
  `филко`,
  `филма`,
  `филми`,
  `филон`,
  `филца`,
  `финал`,
  `финес`,
  `финиш`,
  `финия`,
  `финка`,
  `финки`,
  `финта`,
  `фиорд`,
  `фирка`,
  `фирма`,
  `фирми`,
  `фиска`,
  `фитил`,
  `фишек`,
  `фишът`,
  `флага`,
  `флаер`,
  `фланг`,
  `флаша`,
  `флекс`,
  `флирт`,
  `флокс`,
  `флопи`,
  `флора`,
  `флори`,
  `флота`,
  `флоти`,
  `флуид`,
  `флуор`,
  `флюси`,
  `фоайе`,
  `фобии`,
  `фобия`,
  `фокус`,
  `фолат`,
  `фолиа`,
  `фолио`,
  `фолка`,
  `фонда`,
  `фондю`,
  `фонов`,
  `фонът`,
  `форда`,
  `форма`,
  `форми`,
  `форта`,
  `форум`,
  `форца`,
  `фосил`,
  `фотев`,
  `фотон`,
  `фотос`,
  `фраза`,
  `фрази`,
  `франк`,
  `фрапе`,
  `фреза`,
  `фрези`,
  `фрейм`,
  `фреон`,
  `фриза`,
  `фрица`,
  `фройд`,
  `фронт`,
  `фрукт`,
  `фугас`,
  `фуджи`,
  `фукал`,
  `фукаш`,
  `фукла`,
  `фукна`,
  `фукня`,
  `фунии`,
  `фуния`,
  `фунта`,
  `фураж`,
  `фурен`,
  `фурии`,
  `фурия`,
  `фурма`,
  `фурми`,
  `фурна`,
  `фурни`,
  `фурор`,
  `фуста`,
  `фусти`,
  `футът`,
  `фучащ`,
  `фъкам`,
  `фънка`,
  `фъфля`,
  `фюжън`,
  `фюрер`,
  `фючър`,
  `хабен`,
  `хабер`,
  `хабил`,
  `хабим`,
  `хабих`,
  `хабиш`,
  `хабят`,
  `хаваи`,
  `хаван`,
  `хаджа`,
  `хаджи`,
  `хазар`,
  `хазна`,
  `хазни`,
  `хаити`,
  `хайде`,
  `хайка`,
  `хайки`,
  `хайку`,
  `хайта`,
  `хайти`,
  `хайфа`,
  `хакам`,
  `хакан`,
  `хаква`,
  `хакер`,
  `хакна`,
  `хакне`,
  `хакни`,
  `халал`,
  `халат`,
  `халба`,
  `халби`,
  `халва`,
  `халиф`,
  `халка`,
  `халки`,
  `халфа`,
  `халът`,
  `хамак`,
  `хамал`,
  `хамам`,
  `хамут`,
  `хамър`,
  `ханой`,
  `ханче`,
  `ханша`,
  `ханът`,
  `хаоса`,
  `хаоси`,
  `хапал`,
  `хапва`,
  `хапел`,
  `хапем`,
  `хапеш`,
  `хапещ`,
  `хапка`,
  `хапки`,
  `хапна`,
  `хапне`,
  `хапни`,
  `хапче`,
  `хапят`,
  `харам`,
  `харем`,
  `харен`,
  `харли`,
  `харни`,
  `харно`,
  `харта`,
  `харти`,
  `харча`,
  `харчи`,
  `хасий`,
  `хасия`,
  `хасъл`,
  `хатър`,
  `хауса`,
  `хашиш`,
  `хвала`,
  `хвали`,
  `хваля`,
  `хвана`,
  `хване`,
  `хвани`,
  `хвата`,
  `хваща`,
  `хвоща`,
  `хедер`,
  `хедив`,
  `хедър`,
  `хекът`,
  `хелий`,
  `хелия`,
  `хенри`,
  `хеопс`,
  `хепар`,
  `херои`,
  `херой`,
  `херца`,
  `хиади`,
  `хидра`,
  `хидри`,
  `хиена`,
  `хиени`,
  `хижар`,
  `хикса`,
  `хилав`,
  `хилел`,
  `хилиш`,
  `хилка`,
  `хилки`,
  `хилус`,
  `химен`,
  `химии`,
  `химик`,
  `химия`,
  `химна`,
  `химни`,
  `хинап`,
  `хинди`,
  `хинев`,
  `хинин`,
  `хинов`,
  `хипар`,
  `хисар`,
  `хитев`,
  `хитов`,
  `хитра`,
  `хитри`,
  `хитро`,
  `хитър`,
  `хитът`,
  `хищен`,
  `хищна`,
  `хищни`,
  `хищно`,
  `хладя`,
  `хлапе`,
  `хленч`,
  `хлопа`,
  `хлора`,
  `хляба`,
  `хмела`,
  `хобит`,
  `хобот`,
  `ходел`,
  `ходех`,
  `ходещ`,
  `ходжа`,
  `ходжи`,
  `ходил`,
  `ходим`,
  `ходих`,
  `ходиш`,
  `ходов`,
  `ходом`,
  `ходът`,
  `ходят`,
  `хойка`,
  `хокал`,
  `хокам`,
  `хокан`,
  `хокей`,
  `хокея`,
  `холан`,
  `холен`,
  `холин`,
  `холмс`,
  `холна`,
  `холов`,
  `холът`,
  `хомот`,
  `хомяк`,
  `хонда`,
  `хонди`,
  `хоншу`,
  `хорал`,
  `хорда`,
  `хорди`,
  `хорей`,
  `хорея`,
  `хоров`,
  `хорце`,
  `хорър`,
  `хорът`,
  `хоста`,
  `хотел`,
  `хохот`,
  `храма`,
  `храна`,
  `храни`,
  `храня`,
  `храст`,
  `храча`,
  `храчи`,
  `хрема`,
  `хриле`,
  `хрома`,
  `хроми`,
  `хрущя`,
  `хряна`,
  `хубав`,
  `хуква`,
  `хукна`,
  `хукне`,
  `хулен`,
  `хулил`,
  `хулиш`,
  `хулят`,
  `хумор`,
  `хумус`,
  `хунта`,
  `хунти`,
  `хунхъ`,
  `хунът`,
  `хурия`,
  `хурка`,
  `хурки`,
  `хусар`,
  `хухла`,
  `хъбът`,
  `хъкам`,
  `хълма`,
  `хъмви`,
  `хърба`,
  `хърби`,
  `хърка`,
  `хъски`,
  `хъхри`,
  `хъхря`,
  `хъшът`,
  `цайси`,
  `цакам`,
  `цакаш`,
  `цаков`,
  `цалим`,
  `цанев`,
  `цанен`,
  `цанил`,
  `цанка`,
  `цанко`,
  `цанов`,
  `цаньо`,
  `цапай`,
  `цапам`,
  `цапат`,
  `цапаш`,
  `цапва`,
  `цапна`,
  `цапне`,
  `царев`,
  `царял`,
  `царят`,
  `цачев`,
  `цвета`,
  `цвете`,
  `цветя`,
  `цвика`,
  `цвиля`,
  `цвъка`,
  `цвята`,
  `цевен`,
  `цевна`,
  `цевни`,
  `цевно`,
  `цевта`,
  `цедим`,
  `цедка`,
  `цезар`,
  `цезий`,
  `цезия`,
  `цеков`,
  `цекум`,
  `целен`,
  `целим`,
  `целиш`,
  `целия`,
  `целта`,
  `целял`,
  `целят`,
  `целях`,
  `целящ`,
  `ценев`,
  `ценен`,
  `ценза`,
  `ценил`,
  `ценим`,
  `цениш`,
  `ценка`,
  `ценко`,
  `ценна`,
  `ценни`,
  `ценно`,
  `ценов`,
  `цента`,
  `ценял`,
  `ценят`,
  `ценящ`,
  `цепен`,
  `цепещ`,
  `цепим`,
  `цепиш`,
  `цепка`,
  `цепки`,
  `цепна`,
  `цепят`,
  `церен`,
  `церий`,
  `церия`,
  `церов`,
  `церът`,
  `церях`,
  `цесия`,
  `цехов`,
  `цехът`,
  `цецка`,
  `цецко`,
  `цибет`,
  `цибър`,
  `цивка`,
  `цивря`,
  `цигла`,
  `цигли`,
  `цикас`,
  `цикла`,
  `цикли`,
  `цикля`,
  `циков`,
  `цикъл`,
  `циник`,
  `цинка`,
  `ципът`,
  `циреи`,
  `цирей`,
  `цирея`,
  `цирка`,
  `циста`,
  `цисти`,
  `цитат`,
  `цитра`,
  `цитри`,
  `цифра`,
  `цифри`,
  `цицам`,
  `циция`,
  `цицка`,
  `цицки`,
  `цицна`,
  `цокал`,
  `цокам`,
  `цокащ`,
  `цокли`,
  `цоков`,
  `цокъл`,
  `цолов`,
  `цомби`,
  `цонев`,
  `цонка`,
  `цонко`,
  `цончо`,
  `цоньо`,
  `цопам`,
  `цопан`,
  `цопна`,
  `цочев`,
  `цуйка`,
  `цукам`,
  `цунка`,
  `цупил`,
  `цъкай`,
  `цъкам`,
  `цъкат`,
  `цъкаш`,
  `цъкащ`,
  `цъква`,
  `цъкна`,
  `цъкне`,
  `цъкни`,
  `църва`,
  `църка`,
  `цъфва`,
  `цъфна`,
  `цъфне`,
  `цъфти`,
  `цъфтя`,
  `цюрих`,
  `чавеи`,
  `чавка`,
  `чагос`,
  `чадра`,
  `чадър`,
  `чаеве`,
  `чаена`,
  `чаени`,
  `чаено`,
  `чаира`,
  `чаири`,
  `чайка`,
  `чайки`,
  `чайна`,
  `чайни`,
  `чакай`,
  `чакал`,
  `чакам`,
  `чакан`,
  `чакат`,
  `чаках`,
  `чакаш`,
  `чакащ`,
  `чакра`,
  `чакри`,
  `чакъл`,
  `чалга`,
  `чалги`,
  `чалев`,
  `чалма`,
  `чалми`,
  `чална`,
  `чалъм`,
  `чамла`,
  `чамов`,
  `чанев`,
  `чанта`,
  `чанти`,
  `чанът`,
  `чапла`,
  `чапли`,
  `чарда`,
  `чарди`,
  `чарка`,
  `чарта`,
  `чарът`,
  `часов`,
  `части`,
  `часът`,
  `чатал`,
  `чатам`,
  `чатех`,
  `чатим`,
  `чатна`,
  `чатъм`,
  `чатър`,
  `чатът`,
  `чатят`,
  `чауша`,
  `чауши`,
  `чашка`,
  `чашки`,
  `чвора`,
  `чебър`,
  `чезна`,
  `чезне`,
  `чеиза`,
  `чеизи`,
  `чекия`,
  `чекна`,
  `чеков`,
  `чекът`,
  `челен`,
  `челик`,
  `челия`,
  `челна`,
  `челни`,
  `челно`,
  `челяд`,
  `чемиш`,
  `ченге`,
  `ченъл`,
  `чепат`,
  `чепик`,
  `чепка`,
  `чепки`,
  `чепът`,
  `черва`,
  `черви`,
  `черво`,
  `червя`,
  `черга`,
  `черги`,
  `черда`,
  `черен`,
  `череп`,
  `черна`,
  `черни`,
  `черно`,
  `черня`,
  `черпи`,
  `черпя`,
  `черта`,
  `черти`,
  `честа`,
  `чести`,
  `често`,
  `чесън`,
  `четат`,
  `четем`,
  `четен`,
  `четец`,
  `четеш`,
  `четим`,
  `четка`,
  `четки`,
  `четмо`,
  `четна`,
  `четни`,
  `четно`,
  `четох`,
  `четци`,
  `четял`,
  `четях`,
  `четящ`,
  `чехия`,
  `чехли`,
  `чехъл`,
  `чехът`,
  `чешат`,
  `чешем`,
  `чешеш`,
  `чешит`,
  `чешка`,
  `чешки`,
  `чешко`,
  `чешма`,
  `чешми`,
  `чибук`,
  `чивия`,
  `чието`,
  `чизма`,
  `чизми`,
  `чиито`,
  `чийто`,
  `чикън`,
  `чилев`,
  `чилик`,
  `чиляк`,
  `чинар`,
  `чинел`,
  `чинен`,
  `чинии`,
  `чиниш`,
  `чиния`,
  `чинка`,
  `чинки`,
  `чинна`,
  `чинно`,
  `чинът`,
  `чинят`,
  `чипев`,
  `чипия`,
  `чипка`,
  `чипов`,
  `чипът`,
  `чирак`,
  `чирен`,
  `чиреп`,
  `чироз`,
  `числа`,
  `числи`,
  `число`,
  `чиста`,
  `чисти`,
  `чисто`,
  `чистя`,
  `читав`,
  `читак`,
  `чифта`,
  `чифте`,
  `чифут`,
  `чичил`,
  `чичко`,
  `чичов`,
  `чиято`,
  `члена`,
  `чобан`,
  `човек`,
  `човка`,
  `човки`,
  `чокои`,
  `чокой`,
  `чолак`,
  `чолев`,
  `чомак`,
  `чопля`,
  `чорап`,
  `чорба`,
  `чорби`,
  `чорла`,
  `чорли`,
  `чорля`,
  `чорул`,
  `чохен`,
  `чубра`,
  `чувай`,
  `чувал`,
  `чувам`,
  `чуван`,
  `чуват`,
  `чувах`,
  `чуваш`,
  `чуващ`,
  `чувен`,
  `чугун`,
  `чудак`,
  `чудат`,
  `чудел`,
  `чуден`,
  `чудех`,
  `чудил`,
  `чудим`,
  `чудих`,
  `чудиш`,
  `чудна`,
  `чудни`,
  `чудно`,
  `чудят`,
  `чуела`,
  `чуели`,
  `чуело`,
  `чуете`,
  `чуеха`,
  `чужда`,
  `чужди`,
  `чуждо`,
  `чуйте`,
  `чукай`,
  `чукам`,
  `чукан`,
  `чукар`,
  `чукат`,
  `чуках`,
  `чукаш`,
  `чуква`,
  `чукна`,
  `чукне`,
  `чуков`,
  `чукча`,
  `чукче`,
  `чукчи`,
  `чукът`,
  `чумав`,
  `чумен`,
  `чумна`,
  `чумни`,
  `чупел`,
  `чупен`,
  `чупещ`,
  `чупил`,
  `чупим`,
  `чупка`,
  `чупки`,
  `чупят`,
  `чурек`,
  `чурен`,
  `чурка`,
  `чурук`,
  `чутие`,
  `чутно`,
  `чухал`,
  `чухме`,
  `чухте`,
  `чучул`,
  `чучур`,
  `чушка`,
  `чушки`,
  `шабат`,
  `шабла`,
  `шавай`,
  `шавал`,
  `шавам`,
  `шаван`,
  `шавар`,
  `шават`,
  `шавна`,
  `шавне`,
  `шайба`,
  `шайби`,
  `шайка`,
  `шайки`,
  `шакти`,
  `шалте`,
  `шалче`,
  `шалът`,
  `шаман`,
  `шамар`,
  `шамии`,
  `шамия`,
  `шанел`,
  `шанец`,
  `шанов`,
  `шанса`,
  `шанца`,
  `шанци`,
  `шапен`,
  `шапка`,
  `шапки`,
  `шапче`,
  `шапът`,
  `шаран`,
  `шарен`,
  `шаржа`,
  `шарил`,
  `шарка`,
  `шарки`,
  `шарят`,
  `шатев`,
  `шатра`,
  `шатри`,
  `шатър`,
  `шафер`,
  `шахид`,
  `шахта`,
  `шахти`,
  `шахът`,
  `шашав`,
  `шашка`,
  `шашки`,
  `шашма`,
  `шашми`,
  `шаяци`,
  `шваба`,
  `шваби`,
  `шведа`,
  `шведи`,
  `швепс`,
  `шебек`,
  `шевен`,
  `шевна`,
  `шевни`,
  `шевно`,
  `шейка`,
  `шейна`,
  `шейни`,
  `шейха`,
  `шекел`,
  `шекер`,
  `шелфа`,
  `шемет`,
  `шепна`,
  `шепне`,
  `шепот`,
  `шепти`,
  `шептя`,
  `шериф`,
  `шерпа`,
  `шерпи`,
  `шеста`,
  `шести`,
  `шесто`,
  `шетал`,
  `шетам`,
  `шетат`,
  `шетах`,
  `шетба`,
  `шетни`,
  `шетня`,
  `шефка`,
  `шефки`,
  `шефът`,
  `шехов`,
  `шибал`,
  `шибам`,
  `шибан`,
  `шибат`,
  `шибаш`,
  `шибна`,
  `шибне`,
  `шибой`,
  `шибър`,
  `шивач`,
  `шиела`,
  `шиели`,
  `шиело`,
  `шиене`,
  `шиеше`,
  `шиите`,
  `шиити`,
  `шийка`,
  `шийки`,
  `шийна`,
  `шийни`,
  `шикан`,
  `шинел`,
  `шиник`,
  `шинто`,
  `шипка`,
  `шипки`,
  `шипок`,
  `шипот`,
  `шипче`,
  `шипът`,
  `ширва`,
  `ширел`,
  `ширен`,
  `ширещ`,
  `ширит`,
  `ширна`,
  `ширне`,
  `широк`,
  `ширта`,
  `ширят`,
  `шиста`,
  `шисти`,
  `шифер`,
  `шифон`,
  `шифри`,
  `шифър`,
  `шишко`,
  `шишче`,
  `шията`,
  `шкарп`,
  `шкафа`,
  `шквал`,
  `шкода`,
  `шкоди`,
  `школа`,
  `школи`,
  `школо`,
  `шлайф`,
  `шлака`,
  `шлаки`,
  `шлама`,
  `шланг`,
  `шлейф`,
  `шлема`,
  `шлепа`,
  `шлюза`,
  `шляел`,
  `шляпа`,
  `шляят`,
  `шнапс`,
  `шнола`,
  `шнура`,
  `шогун`,
  `шоков`,
  `шокът`,
  `шолев`,
  `шопар`,
  `шопка`,
  `шопов`,
  `шопци`,
  `шопче`,
  `шопът`,
  `шорти`,
  `шоута`,
  `шоуто`,
  `шпага`,
  `шпаги`,
  `шпайк`,
  `шперц`,
  `шпила`,
  `шпора`,
  `шпори`,
  `шпоря`,
  `шприц`,
  `шпула`,
  `шпули`,
  `шрифт`,
  `шрота`,
  `шугав`,
  `шукна`,
  `шумак`,
  `шумен`,
  `шумер`,
  `шумиш`,
  `шумка`,
  `шумки`,
  `шумна`,
  `шумни`,
  `шумно`,
  `шумов`,
  `шумол`,
  `шумтя`,
  `шумът`,
  `шумят`,
  `шумящ`,
  `шунка`,
  `шунки`,
  `шупла`,
  `шупли`,
  `шупна`,
  `шупне`,
  `шурей`,
  `шурея`,
  `шурна`,
  `шурти`,
  `шуртя`,
  `шутът`,
  `шушка`,
  `шушна`,
  `шушня`,
  `шушон`,
  `шхуна`,
  `шхуни`,
  `щабен`,
  `щабна`,
  `щабни`,
  `щабно`,
  `щабът`,
  `щавач`,
  `щавел`,
  `щавят`,
  `щадим`,
  `щадял`,
  `щадят`,
  `щадящ`,
  `щайга`,
  `щайги`,
  `щампа`,
  `щампи`,
  `щамът`,
  `щанга`,
  `щанги`,
  `щанда`,
  `щанци`,
  `щатен`,
  `щатна`,
  `щатни`,
  `щатно`,
  `щатът`,
  `щедра`,
  `щедри`,
  `щедро`,
  `щедър`,
  `щекер`,
  `щеков`,
  `щелия`,
  `щение`,
  `щения`,
  `щерев`,
  `щерка`,
  `щерки`,
  `щерко`,
  `щерна`,
  `щерни`,
  `щерьо`,
  `щиков`,
  `щипец`,
  `щипка`,
  `щипки`,
  `щипна`,
  `щипок`,
  `щипци`,
  `щипят`,
  `щирът`,
  `щитче`,
  `щитът`,
  `щифта`,
  `щолен`,
  `щрайх`,
  `щрака`,
  `щранг`,
  `щрасе`,
  `щраус`,
  `щриха`,
  `щрихи`,
  `щукна`,
  `щукне`,
  `щурав`,
  `щурак`,
  `щурам`,
  `щурец`,
  `щурея`,
  `щурма`,
  `щурци`,
  `щурче`,
  `щурчо`,
  `щуцер`,
  `щъкам`,
  `щърба`,
  `щърби`,
  `щърка`,
  `щяхме`,
  `щяхте`,
  `ъглен`,
  `ъглов`,
  `ъгъла`,
  `ърбън`,
  `ървин`,
  `юанът`,
  `югово`,
  `югхур`,
  `юдеин`,
  `юдите`,
  `южния`,
  `южняк`,
  `юзина`,
  `юзъра`,
  `юзъри`,
  `юлара`,
  `юлари`,
  `юлиян`,
  `юлска`,
  `юлски`,
  `юлско`,
  `юмрук`,
  `юнака`,
  `юнако`,
  `юнаци`,
  `юначе`,
  `юникс`,
  `юнити`,
  `юница`,
  `юнкер`,
  `юнона`,
  `юноша`,
  `юноши`,
  `юнска`,
  `юнски`,
  `юнско`,
  `юпита`,
  `юрвам`,
  `юрган`,
  `юрдек`,
  `юриев`,
  `юрист`,
  `юркам`,
  `юрках`,
  `юрнал`,
  `юрнек`,
  `юрска`,
  `юрски`,
  `юрско`,
  `юруци`,
  `ютите`,
  `яблан`,
  `явила`,
  `явили`,
  `явило`,
  `явиха`,
  `явния`,
  `явора`,
  `явори`,
  `явява`,
  `ягода`,
  `ягоди`,
  `ягуар`,
  `ягула`,
  `ягули`,
  `ядвам`,
  `ядели`,
  `ядена`,
  `ядене`,
  `ядени`,
  `ядете`,
  `ядеше`,
  `ядлив`,
  `ядове`,
  `ядоса`,
  `ядрен`,
  `ядяла`,
  `ядяло`,
  `ядяха`,
  `ядящи`,
  `ядящо`,
  `язвен`,
  `яздач`,
  `яздел`,
  `яздещ`,
  `яздил`,
  `яздиш`,
  `яздят`,
  `язове`,
  `яйчар`,
  `яйчен`,
  `яйчна`,
  `яйчни`,
  `яйчно`,
  `якане`,
  `яката`,
  `якета`,
  `якето`,
  `яките`,
  `якият`,
  `якова`,
  `якове`,
  `яково`,
  `якост`,
  `якото`,
  `ялите`,
  `ялова`,
  `ялови`,
  `ялово`,
  `ямаец`,
  `ямата`,
  `ямаха`,
  `ямбол`,
  `ямбът`,
  `ямино`,
  `ямите`,
  `янаки`,
  `янгун`,
  `яндзъ`,
  `янева`,
  `янино`,
  `янита`,
  `яница`,
  `янков`,
  `янова`,
  `яново`,
  `янтар`,
  `янтра`,
  `янчев`,
  `ярема`,
  `ярета`,
  `ярето`,
  `ярина`,
  `яркия`,
  `ярост`,
  `ясена`,
  `ясени`,
  `ясмин`,
  `яснее`,
  `яснея`,
  `ясния`,
  `яспис`,
  `ястие`,
  `ястия`,
  `ятака`,
  `ятако`,
  `ятата`,
  `ятаци`,
  `ятова`,
  `ятово`,
  `ятото`,
  `яунде`,
  `яхали`,
  `яхане`,
  `яхвал`,
  `яхвам`,
  `яхват`,
  `яхнал`,
  `яхнат`,
  `яхнеш`,
  `яхнии`,
  `яхния`,
  `яхтен`,
  `яхъри`,
  `ячков`,
  `яшмак`,
]
