import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Как се играе?" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Имаш 6 опита за да отгатнеш думата на деня. След всеки опит, цвета на крадратчетата ще се промени за да покаже колко близо си до отгатването на думата.        
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="К" status="correct" />
        <Cell value="О" />
        <Cell value="З" />
        <Cell value="Е" />
        <Cell value="Л" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Буквата К присъства в думата и е на правилна позиция.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="П" />
        <Cell value="И" />
        <Cell value="Л" status="present" />
        <Cell value="О" />
        <Cell value="Т" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Буквата Л присъства в думата, но е на грешна позиция.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="К" />
        <Cell value="У" />
        <Cell value="Х" />
        <Cell value="Н" status="absent" />
        <Cell value="Я" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Буквата Н не присъства в думата.
      </p>
    </BaseModal>
  )
}
