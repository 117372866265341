export const GAME_TITLE = 'ИГРА НА ДУМИ'
// export const SHARE_NAME = '<a href="https://wordle.bg">WORDLE.BG</a>'
export const SHARE_NAME = 'https://wordle.bg'

export const WIN_MESSAGES = ['Добра работа!', 'Страхотно!', 'Невероятно!', 'Фантастично!', 'Супер си!']
export const GAME_COPIED_MESSAGE = 'Играта е копирана'
export const ABOUT_GAME_MESSAGE = 'За BoomRaid™'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Трябват още букви'
export const WORD_NOT_FOUND_MESSAGE = 'Думата не е открита'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Думата беше ${solution}`
export const ENTER_TEXT = 'Въведи'
export const DELETE_TEXT = 'Изтрий'
export const STATISTICS_TITLE = 'Статистика'
export const GUESS_DISTRIBUTION_TEXT = 'Дистрибуция на опитите'
export const NEW_WORD_TEXT = 'Нова дума след'
export const SHARE_TEXT = 'Сподели'
export const TOTAL_TRIES_TEXT = 'Брой игри'
export const SUCCESS_RATE_TEXT = 'Успеваемост'
export const CURRENT_STREAK_TEXT = 'Поредни победи'
export const BEST_STREAK_TEXT = 'Най-добра серия'
